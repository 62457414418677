import {numberBooleanColumnDef} from "../../../agGridUtils";
import PropTypes from "prop-types";
import {Button, IconButton} from "@mui/material";
import {OpenInNew as OpenInNewIcon} from "@mui/icons-material";

const UseColumnDefs = ({salesCellEditorSelector}) => {

    return [
        {
            field: "SALES",
            headerName: "担当者名",
            lockPosition: "left",
            editable: true,
            cellEditorSelector: salesCellEditorSelector,
            cellRenderer: (p) => {
                return p.value?.NAME
            },
            cellStyle: (p) => {
                if (!p.value) { return null }
                if (p.value.UID !== p.data?.SALES_UID) {
                    return {backgroundColor: "rgba(161,161,246,0.4)"}
                }
                return null
            },
            filterParams: "distinctSales",
        },
        {
            field: "ASSIGNED_SALES_ID",
            headerName: "担当者ID",
            hide: true,
            width: 200,
        },
        {
            field: "SALES_STAGE",
            headerName: "商談ステータス",
            filterParams: "distinct",
            width: 160,
            hide: true
        },
        {
            field: "HANA_MATCH_FLAG",
            headerName: "既存店フラグ(HANA)",
            filterParams: "distinct",
            width: 181,
        },
        {
            field: "NSR_PRED",
            headerName: "NSR優先",
            filterParams: "distinct",
            width: 121,
        },
        {
            field: "SAKAYA_MATCH_FLAG",
            headerName: "既存店フラグ(酒屋)",
            filterParams: "distinct",
            width: 170,
        },
        {
            field: "SAKAYA_NAME",
            headerName: "酒屋様名",
            filter: "agTextColumnFilter",
            width: 150,
            hide: true
        },
        {
            field: "HANA_DEALER_CODE",
            headerName: "HANAディーラーコード",
            filter: "agTextColumnFilter",
            width: 200,
            hide: true
        },
        {
            field: "TABELOG_ID",
            headerName: "ID",
            filter: "agNumberColumnFilter",
            width: 120,
        },
        {
            field: "RST_TITLE",
            headerName: "食べログ名称",
            filter: "agTextColumnFilter",
            width: 286,
        },
        {
            field: "RST_URL",
            headerName: "食べログリンク",
            cellRenderer: (e) => {
                if (!e.value) { return null }
                console.log("[TABELOG]", "Link", e)
                return (
                    <IconButton size="small" onClick={() => {
                        window.open(e.value)
                    }}>
                        <OpenInNewIcon style={{fontSize: "16px"}} />
                    </IconButton>
                )
            },
            width: 150,
            suppressCellFocus: true,
            suppressCellSelection: true,
        },
        {
            field: "PREFECTURE",
            headerName: "食べログ都道府県",
            filterParams: "distinct",
            width: 160,
        },
        {
            field: "CITY",
            headerName: "食べログ市・区",
            filterParams: "distinct",
            width: 150,
        },
        {
            field: "CUISINE_CAT_1",
            headerName: "食べログ業態(全部)",
            filterParams: "distinct",
            width: 170,
        },
        {
            field: "IS_FAMILY_FRIENDLY",
            headerName: "食べログ家族",
            width: 140,
            cellDataType: "boolean",
            filterParams: "boolean",
        },
        {
            field: "IS_FRIEND_FRIENDLY",
            headerName: "食べログ友人",
            width: 140,
            cellDataType: "boolean",
            filterParams: "boolean",
        },
        {
            field: "IS_ALONE_FRIENDLY",
            headerName: "食べログ一人",
            width: 140,
            cellDataType: "boolean",
            filterParams: "boolean",
        },
        {
            field: "ACCESS_METER_MIN",
            headerName: "食べログ　最寄駅からの最小距離(m)",
            filter: "agNumberColumnFilter",
            width: 270,
        },
        {
            field: "ACCESS_METER_MAX",
            headerName: "食べログ　最寄駅からの最大距離(m)",
            filter: "agNumberColumnFilter",
            width: 270,
        },
        {
            field: "LUNCH_PRICE",
            headerName: "食べログ　昼ごはんの価格",
            filter: "agNumberColumnFilter",
            width: 210,
        },
        {
            field: "DINNER_PRICE",
            headerName: "食べログ　夜ごはんの価格",
            filter: "agNumberColumnFilter",
            width: 210,
        },
        {
            field: "RATING_CNT",
            headerName: "食べログコロミ数",
            filter: "agNumberColumnFilter",
            width: 160,
        },
        {
            field: "RATING_SCORE",
            headerName: "食べログコロミスコア",
            filter: "agNumberColumnFilter",
            width: 190,
        },
        {
            field: "HAS_HOME_PAGE_URL",
            headerName: "食べログホームページ",
            cellDataType: "boolean",
            filterParams: "boolean",
            width: 190,
        },
        {
            field: "DAYS_OPEN_WEEK",
            headerName: "食べログ営業日（平日）",
            filter: "agNumberColumnFilter",
            width: 200,
        },
        {
            field: "DAYS_OPEN_WEEKEND",
            headerName: "食べログ営業日（週末）",
            filter: "agNumberColumnFilter",
            width: 200,
        },
        {
            field: "CASH_ONLY",
            headerName: "食べログ支払方法",
            cellDataType: "boolean",
            filterParams: "boolean",
            width: 160,
        },
        {
            field: "NUM_SEATS",
            headerName: "食べログ席数",
            filter: "agNumberColumnFilter",
            width: 140,
        },
        {
            field: "NO_SMOKING",
            headerName: "食べログ禁煙/喫煙",
            cellDataType: "boolean",
            filterParams: "boolean",
            width: 170,
        },
        {
            field: "HAS_PARKING",
            headerName: "食べログ駐車場",
            cellDataType: "boolean",
            filterParams: "boolean",
            width: 150,
        },
        {
            field: "MONTHS_SINCE_OPEN",
            headerName: "食べログオープン日",
            filter: "agNumberColumnFilter",
            width: 175,
        },
        {
            field: "HAS_TABEHODAI",
            headerName: "食べログコースメニュー",
            cellDataType: "boolean",
            filterParams: "boolean",
            width: 200,
        },
        {
            field: "HAS_NOMIHODAI",
            headerName: "食べログコースメニュー",
            cellDataType: "boolean",
            filterParams: "boolean",
            width: 200,
        },
        {
            field: "LATITUDE",
            headerName: "食べログ緯度",
            cellDataType: "number",
            hide: true,
        },
        {
            field: "LONGITUDE",
            headerName: "食べログ経度",
            cellDataType: "number",
            hide: true,
        },
        {
            field: "POPULATION_LAST_MONTH",
            headerName: "前月の人口",
            filter: "agNumberColumnFilter",
            width: 175,
        },
        {
            field: "POPULATION_LAST_12_MONTHS",
            headerName: "過去12ヶ月の人口",
            filter: "agNumberColumnFilter",
            width: 175,
        },
    ]
}

UseColumnDefs.propTypes = {
    salesCellEditorSelector: PropTypes.any,
}

export default UseColumnDefs
