import React, {useCallback, useContext, useEffect, useMemo, useState} from "react"
import PropTypes from "prop-types";
import {MapDataContext} from "../index";
import BaseView from "./base";
import {First} from "../../../../manager/carto3";
import {Box, Button, Slider, TextField} from "@mui/material";
import {ConditionType} from "../../../../data/map/state";
import _ from "lodash";
import {DateMode} from "../../../../data/main/state";
import LegendView from "../legend";
import {MainDataContext} from "../../../main";

const styles = {
    root: {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
    },
    sliderBox: {
        margin: "0 2rem",
        width: "500px",
    },
    inputsBox: {
        display: "flex",
        flexDirection: "row",
        gap: "8px",
    },
}

const RootMapConditionRangeView = React.memo(({type, dateMode, userId, unit, title}) => {

    const { state: mainState, appendCondition, clearCondition } = useContext(MainDataContext)
    const { state } = useContext(MapDataContext)

    const [min, setMin] = useState()
    const [max, setMax] = useState()
    const [conditionValue, setConditionValue] = useState()
    const [marks, setMarks] = useState()
    const [openLegends, setOpenLegends] = useState()

    const SLIDER_MIN = 0
    const SLIDER_MAX = 5
    const SLIDER_STEP = 1

    useEffect(() => {
        if (!state.visibleConditionType || !mainState.user?.ID) { return }

        let wheres = [`TRUE`]
        const suffix = mainState.dateMode === DateMode.Monthly ? "_LAST_MONTH" : "_LAST_12_MONTHS"
        const col = `${state.visibleConditionType.replace("_TY", "")}${suffix}`

        First(`
        SELECT
            PERCENTILE_CONT(0.2) WITHIN GROUP ( ORDER BY CASE WHEN ${col} > 0 THEN ${col} END) AS CLASS_1,
            PERCENTILE_CONT(0.4) WITHIN GROUP ( ORDER BY CASE WHEN ${col} > 0 THEN ${col} END) AS CLASS_2,
            PERCENTILE_CONT(0.6) WITHIN GROUP ( ORDER BY CASE WHEN ${col} > 0 THEN ${col} END) AS CLASS_3,
            PERCENTILE_CONT(0.8) WITHIN GROUP ( ORDER BY CASE WHEN ${col} > 0 THEN ${col} END) AS CLASS_4,
            MIN(${col}) AS MIN,
            MAX(${col}) AS MAX
        FROM ${process.env.REACT_APP_CARTO_TABLE_SALES_H3_VIEW}_${mainState.user.ID.toString().padStart(8, '0')} AS base
        WHERE ${wheres.join(' AND ')}
        `).then(row => {
            setMarks([
                {label: parseInt(row.MIN).toLocaleString(), value: 0},
                {label: parseInt(row.CLASS_1).toLocaleString(), value: 1},
                {label: parseInt(row.CLASS_2).toLocaleString(), value: 2},
                {label: parseInt(row.CLASS_3).toLocaleString(), value: 3},
                {label: parseInt(row.CLASS_4).toLocaleString(), value: 4},
                {label: parseInt(row.MAX).toLocaleString(), value: 5},
            ])
            
            setConditionValue([
                row.MIN,
                row.CLASS_1,
                row.CLASS_2,
                row.CLASS_3,
                row.CLASS_4,
                row.MAX,
            ])
        })

    }, [state.visibleConditionType, mainState.dateMode, mainState.user])

    const text = useMemo(() => {
        if (mainState.conditions[type]) {
            return `${parseInt(mainState.conditions[type][0]).toLocaleString()}${unit ?? ""}〜${parseInt(mainState.conditions[type][1]).toLocaleString()}${unit ?? ""}`
        } else {
            return "未設定"
        }
    }, [mainState.conditions, type, unit])

    useEffect(() => {
        // let minCol = `${type}_MIN`
        // let maxCol = `${type}_MAX`
        // if (type === ConditionType.POPULATION) {
        //     minCol = `${type}_MAX`
        //     maxCol = `${type}_MAX`
        // }

        const col = `${type.replace("_TY", "")}_${dateMode === DateMode.Monthly ? "LAST_MONTH" : "LAST_12_MONTHS"}`

        First(`
        SELECT
            MIN(${col}) AS MIN,
            MAX(${col}) AS MAX
        FROM ${process.env.REACT_APP_CARTO_TABLE_SALES_H3_VIEW}_${userId.toString().padStart(8, '0')} AS base
        `).then(row => {
            setMin(row.MIN)
            setMax(row.MAX)
            // let range = (row.MAX - row.MIN) / 5
            // let m = []
            // for(let i = 0; i < 5; i++) {
            //     m.push({
            //         value: parseInt(row.MIN + range * i),
            //         label: `${parseInt(row.MIN + range * i).toLocaleString()}${unit ?? ""}`,
            //     })
            // }
            // m.push({
            //     value: parseInt(row.MAX),
            //     label: `${parseInt(row.MAX).toLocaleString()}${unit ?? ""}`
            // })
            // setMarks(m)
        })
    }, [])

    const onChangeSlider = _.debounce((e) => {
        const val1 = conditionValue[e.target.value[0]]
        const val2 = conditionValue[e.target.value[1]]

        appendCondition({[type]: [val1, val2]})
    }, 0)

    const sliderValue = useMemo(() => {
        if (mainState.conditions[type] && conditionValue) {
            const val1 = conditionValue.findIndex(num => num === mainState.conditions[type][0])
            const val2 = conditionValue.findIndex(num => num === mainState.conditions[type][1])
            return [val1, val2]
        }
        
        return [SLIDER_MIN, SLIDER_MAX]
    }, [mainState.conditions, min, max, conditionValue])

    return (
        <BaseView text={text} type={type} title={title} key={`${type}-base-view`}>
            <Box style={styles.root}>
                <Box style={styles.sliderBox}>
                    <Slider
                        key={`${type}-slider`}
                        min={SLIDER_MIN}
                        max={SLIDER_MAX}
                        step={SLIDER_STEP}
                        value={sliderValue}
                        onChange={onChangeSlider}
                        valueLabelDisplay="off"
                        marks={marks}
                    />
                </Box>
                <Box style={styles.inputsBox}>
                    <Box style={{flexGrow: 1}} />
                    <Button onClick={() => setOpenLegends(true)}>凡例</Button>
                    <Button key={`${type}-clear-button`} onClick={() => clearCondition(type)}>クリア</Button>
                </Box>
            </Box>
            <LegendView open={openLegends} onClose={() => setOpenLegends(false)} />
        </BaseView>
    )
})

RootMapConditionRangeView.propTypes = {
    type: PropTypes.string.isRequired,
    dateMode: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    unit: PropTypes.string,
}

export default RootMapConditionRangeView
