import React, { useContext } from 'react';
import {
    Box,
    Button
} from "@mui/material";
import { MainDataContext } from '../../../main';

export const SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED = "SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED"
export const SHOW_TABELOG_POINT_TYPE_HANA_MATCH_FLAG = "SHOW_TABELOG_POINT_TYPE_HANA_MATCH_FLAG"
export const SHOW_TABELOG_POINT_TYPE_SAKAYA_MATCH_FLAG = "SHOW_TABELOG_POINT_TYPE_SAKAYA_MATCH_FLAG"
export const SHOW_TABELOG_POINT_TYPE_NSR_PRED = "SHOW_TABELOG_POINT_TYPE_NSR_PRED"
export const SHOW_TABELOG_POINT_TYPE_THE_OTHERS = "SHOW_TABELOG_POINT_TYPE_THE_OTHERS"

const styles = {
    TabelogLegendBox: {
        position: 'absolute',
        bottom: '3rem',
        left: '1rem',
        zIndex: '100',
        background: 'white',
        padding: '8px 16px',
        borderRadius: '8px',
        boxShadow: 'rgb(0, 0, 0) 1px 1px 3px'
    },
    legendButton: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center',
        padding: 0,
        backgroundColor: 'transparent',
        lineHeight: 1.5,
    },
    legendsText: {
        fontSize: "12px"
    },
    colorCircle: {
        width: "10px",
        height: "10px",
        borderRadius: "50%"
    }
};

export const TabelogPointLegend = () => {

    const {
        state,
        setShowTabelogPointType
    } = useContext(MainDataContext);

    const handleOnClick = (event) => {
        event.stopPropagation()
        if (state.showTabelogPointType !== event.currentTarget.id) {
            setShowTabelogPointType(event.currentTarget.id)
        } else {
            setShowTabelogPointType(null)
        }
    }

    const getColor = (type) => {
        if (state.showTabelogPointType === null || state.showTabelogPointType === type) {
            return "black"
        }
        return "gray"
    }

    return (
        <Box style={styles.TabelogLegendBox}>
            <Button id={SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED} style={styles.legendButton} mb={0.2} onClick={handleOnClick} disableRipple>
                <Box style={{...styles.colorCircle, background: "#9AAABF"}} />
                <Box style={{...styles.legendsText, color: getColor(SHOW_TABELOG_POINT_TYPE_IS_ASSIGNED)}} ml={1}>新規(SFA登録済み)</Box>
            </Button>
            <Button id={SHOW_TABELOG_POINT_TYPE_HANA_MATCH_FLAG} style={styles.legendButton} mb={0.2} onClick={handleOnClick} disableRipple>
                <Box style={{...styles.colorCircle, background: "#FB001E"}} />
                <Box style={{...styles.legendsText, color: getColor(SHOW_TABELOG_POINT_TYPE_HANA_MATCH_FLAG)}} ml={1}>既存(直取)</Box>
            </Button>
            <Button id={SHOW_TABELOG_POINT_TYPE_SAKAYA_MATCH_FLAG} style={styles.legendButton} mb={0.2} onClick={handleOnClick} disableRipple>
                <Box style={{...styles.colorCircle, background: "#17A644"}} />
                <Box style={{...styles.legendsText, color: getColor(SHOW_TABELOG_POINT_TYPE_SAKAYA_MATCH_FLAG)}} ml={1}>既存(酒屋経由)</Box>
            </Button>
            <Button id={SHOW_TABELOG_POINT_TYPE_NSR_PRED} style={styles.legendButton} mb={0.2} onClick={handleOnClick} disableRipple>
                <Box style={{...styles.colorCircle, background: "#2264B7"}} />
                <Box style={{...styles.legendsText, color: getColor(SHOW_TABELOG_POINT_TYPE_NSR_PRED)}} ml={1}>新規(ハイポテンシャル)</Box>
            </Button>
            <Button id={SHOW_TABELOG_POINT_TYPE_THE_OTHERS} style={styles.legendButton} mb={0.2} onClick={handleOnClick} disableRipple>
                <Box style={{...styles.colorCircle, background: "#FEB70C"}} />
                <Box style={{...styles.legendsText, color: getColor(SHOW_TABELOG_POINT_TYPE_THE_OTHERS)}} ml={1}>新規(その他)</Box>
            </Button>
        </Box>
    );
}