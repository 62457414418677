import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {vectorQuerySource, VectorTileLayer} from "@deck.gl/carto";
import PropTypes from "prop-types";
import {DataType, DateMode} from "../../../../data/main/state";
import {filterModelToWheres} from "../../../../manager/filter";
import {MainDataContext} from "../../../main";
import {One} from "../../../../manager/carto3";
import {MapDataContext} from "../index";

const UseRootMapSalesPointLayer = (props) => {

    const { state } = useContext(MainDataContext)
    const { state: mapState } = useContext(MapDataContext)
    const [filterModel, setFilterModel] = useState(null);
    const [layer, setLayer] = useState(null)

    const checkCount = async (wheres, values) => {
        let cnt = await One(`
        SELECT COUNT(1)
        FROM ${process.env.REACT_APP_CARTO_TABLE_SALES_POINT} AS point
        INNER JOIN (SELECT ID AS SALES_ID, GEOMETRY FROM ${process.env.REACT_APP_CARTO_TABLE_SALES}) AS sales
        ON ST_CONTAINS(sales.geometry, point.geom)        
        WHERE ${wheres.join(' AND ')}
        `, values)
        return cnt > 0
    }

    const data = useMemo(async () => {
        let wheres = filterModelToWheres(state.filterModelSales)
        let values = []

        wheres.push(`SALES_ID = ${state.user.ID}`)
        wheres.push('GEOM_GEOHASH is not null')

        const colSuffix = state.dateMode === DateMode.Monthly ? "_LAST_MONTH" : "_LAST_12_MONTHS"

        if (!mapState.showPointLayer || state.dataType !== DataType.SALES) {
            setTimeout(() => {
                props.onLoadFinish()
            }, 1000)
            return null
        }

        if (!(await checkCount(wheres, values))) {
            setTimeout(() => {
                props.onLoadFinish()
            }, 1000)
            return null
        }

        const sqlQuery = `
        SELECT
            GEOM,
            ZJDLRC,
            ZJDLRC___T,
            ZJCUST___T,
            ADDR_SOLD_TO,
            ADDR_SHIP_TO,
            BAPC${colSuffix} AS BAPC_TY,
            GP${colSuffix} AS GP_TY,
            NSR${colSuffix} AS NSR_TY,
            BAPC_LAST_MONTH,
            GP_LAST_MONTH,
            NSR_LAST_MONTH,
            BAPC_LAST_12_MONTHS,
            GP_LAST_12_MONTHS,
            NSR_LAST_12_MONTHS,
            POPULATION_LAST_MONTH,
            POPULATION_LAST_12_MONTHS
        FROM ${process.env.REACT_APP_CARTO_TABLE_SALES_POINT} AS point
        INNER JOIN (SELECT ID AS SALES_ID, GEOMETRY FROM ${process.env.REACT_APP_CARTO_TABLE_SALES}) AS sales
        ON ST_CONTAINS(sales.geometry, point.geom)                 
        WHERE ${wheres.join(' AND ')}
        `

        console.log('[SalesPoint]', 'query', sqlQuery, values)

        return vectorQuerySource({
            accessToken: process.env.REACT_APP_CARTO_ACCESS_TOKEN,
            connectionName: 'snowflakeconn',
            apiBaseUrl: 'https://gcp-asia-northeast1.api.carto.com',
            sqlQuery,
            queryParameters: values,
            loadOptions: {
                fetch: {
                    headers: {
                        "Cache-control": "max-age=1296000"
                    }    
                }
            }

        });
    }, [
        state.filterModelSales,
        state.dateMode,
        mapState.showPointLayer,
        state.dataType,
        state.user,
    ], 100);

    const getFillColor = useCallback((p) => {
        return [0,255,0]
    }, [state.selectedTabelogIds])

    useEffect(() => {
        props.onLoadStart && props.onLoadStart()
    }, [filterModel, state.startDate, state.endDate])

    useEffect(() => {
        data.then(d => {
            if (d) {
                setLayer(
                    new VectorTileLayer({
                        id: 'retail_stores',
                        data,
                        getFillColor,
                        getPointRadius: 2,
                        pointRadiusScale: 2,
                        pointRadiusUnits: "pixels",
                        getLineColor: [0,0,0,100],
                        getLineWidth: 0.1,
                        lineWidthScale: 1,
                        pickable: true,
                        onDataLoad: () => {
                        },
                        onViewportLoad: e => {
                            props.onLoadFinish && props.onLoadFinish()
                        },
                        onClick: props.onClick,
                        onHover: props.onHover,
                        // updateTriggers: {
                        //     getFillColor: [state.selectedTabelogIds],
                        // }
                    })
                )
            } else {
                setLayer(null)
            }
        })
    }, [data])

    return layer
}

UseRootMapSalesPointLayer.propTypes = {
    onClick: PropTypes.func,
    onHover: PropTypes.func,
    onLoadStart: PropTypes.func,
    onLoadFinish: PropTypes.func,
}

export default UseRootMapSalesPointLayer
