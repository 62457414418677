import React, {useContext, useMemo} from "react"
import {Box, IconButton, Typography} from "@mui/material";
import {Close as CloseIcon} from "@mui/icons-material";
import {MainDataContext} from "../../main";
import { ColumnDefs } from "../list/column"
import { ColumnDefs as ColumnDefsForSales } from "../list_sales/column"
import {filterModelToValueString} from "../../../manager/filter";
import PropTypes from "prop-types";
import {DataType} from "../../../data/main/state";

const styles = {
    root: {
        minHeight: "60px",
        overflowX: "auto",
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        alignItems: "center",
        background: "#e5fbf3",
    },
    filterItem: {
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        alignItems: 'center',
        background: '#4a7546',
        padding: '4px 8px',
        borderRadius: '16px',
        boxShadow: '1px 1px 3px #eee'
    },
    filterItemTitle: {
        color: '#f1f1f1',
        fontWeight: 'bold',
        fontSize: '13px'
    },
    filterItemValues: {
        fontSize: "12px",
        color: "#ffffff"
    },
    filterItemDeleteButton: {

    },
    filterItemDeleteIcon: {
        fontSize: "13px",
        color: "#fff",
    },
    selectItem: {
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        alignItems: 'center',
        background: '#4c3fac',
        padding: '4px 8px',
        borderRadius: '16px',
        boxShadow: '1px 1px 3px #eee'
    },
    selectItemValue: {
        fontSize: "12px",
        color: "#fff",
        marginLeft: "2px",
    },
    selectItemDeleteButton: {
    },
    selectItemDeleteIcon: {
        fontSize: "13px",
        color: "#fff",
    },
    assignText: {
        fontSize: "14px",
        marginLeft: "1rem"
    },
    filterText: {
        fontSize: "14px",
        marginLeft: "0.5rem"
    },
}

const RootFilterView = (props) => {

    const { 
        state, 
        setSelectedTabelogIds, 
        setFilterModel, 
        setRemoveFilter, 
        setFilterModelSales, 
        setSelectedH3List, 
        clearCondition 
    } = useContext(MainDataContext)

    const onDeleteItem = (field) => {
        let f = {...state.filterModel}
        delete f[field]
        setRemoveFilter(field)
        setFilterModel(f)
    }

    const onDeleteItemForSales = (field) => {
        let f = {...state.filterModelSales}
        delete f[field]
        setRemoveFilter(field)
        setFilterModelSales(f)
    }

    const onClearCondition = (value) => {
        clearCondition(value[0])
    }

    const onDeleteSelect = () => {
        setSelectedTabelogIds([])
        setSelectedH3List([])
    }

    return (
        <Box style={styles.root}>
            {state.dataType === DataType.TABELOG && state.selectedTabelogIds.length > 0 && (
                <>
                <Typography style={styles.assignText}>アサイン選択：</Typography>
                <Box style={styles.selectItem}>
                    <Typography style={styles.selectItemValue}>{state.selectedTabelogIds.length.toLocaleString()}件選択中</Typography>
                    <IconButton style={styles.selectItemDeleteButton} onClick={onDeleteSelect}>
                        <CloseIcon style={styles.selectItemDeleteIcon} />
                    </IconButton>
                </Box>
                </>
            )}


            {state.dataType === DataType.SALES && state.selectedH3List.length > 0 && (
                <>
                <Typography style={styles.assignText}>グリッド選択：</Typography>
                <Box style={styles.selectItem}>
                    <Typography style={styles.selectItemValue}>{state.selectedH3List.length.toLocaleString()}件選択中</Typography>
                    <IconButton style={styles.selectItemDeleteButton} onClick={onDeleteSelect}>
                        <CloseIcon style={styles.selectItemDeleteIcon} />
                    </IconButton>
                </Box>
                </>
            )}

            <Typography style={styles.filterText}>フィルター：</Typography>

            {state.conditions && Object.entries(state.conditions).map((value, _) => 
                <FilterItemForConditions value={value} onClear={() => onClearCondition(value)} />
            )}

            {state.dataType === DataType.TABELOG && state.filterModel && Object.entries(state.filterModel).map(([field, filter]) =>
                <FilterItem dataType={DataType.TABELOG} field={field} filter={filter} onDelete={() => onDeleteItem(field)} />
            )}

            {state.dataType === DataType.SALES && state.filterModelSales && Object.entries(state.filterModelSales).map(([field, filter]) =>
                <FilterItem dataType={DataType.SALES} field={field} filter={filter} onDelete={() => onDeleteItemForSales(field)} />
            )}

            
        </Box>
    )

}

export default RootFilterView

const FilterItem = ({dataType, field, filter, onDelete}) => {

    const name = useMemo(() => {
        let name = ""
        if (dataType === DataType.TABELOG) {
            const item = ColumnDefs.find(col => col.field === field)
            name = item?.headerName

        } else if (dataType === DataType.SALES) {
            const item = ColumnDefsForSales.find(col => col.field === field)
            name = item?.headerName
        }

        return name
    }, [field])

    const value = useMemo(() => {
        return filterModelToValueString(filter)
    }, [filter])

    return name ? (
        <Box style={styles.filterItem}>
            <Typography style={styles.filterItemTitle}>{name}：</Typography>
            <Typography style={styles.filterItemValues}>{value}</Typography>
            <IconButton style={styles.filterItemDeleteButton} onClick={onDelete}>
                <CloseIcon style={styles.filterItemDeleteIcon} />
            </IconButton>
        </Box>
    ) : null
}

FilterItem.propTypes = {
    field: PropTypes.string.isRequired,
    filter: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
}

const FilterItemForConditions = ({value, onClear}) => {
    const title = value[0]
    const range = value[1]
    let from = 0
    let to = 0
    if (range) {
        from = range[0]
        to = range[1]
    }
    
    return range ? (
        <Box style={styles.filterItem}>
            <Typography style={styles.filterItemTitle}>{title}：</Typography>
            <Typography style={styles.filterItemValues}>{`${from} ~ ${to}`}</Typography>
            <IconButton style={styles.filterItemDeleteButton} onClick={onClear}>
                <CloseIcon style={styles.filterItemDeleteIcon} />
            </IconButton>
        </Box>
    ) : null
}