import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {colorContinuous, h3QuerySource, H3TileLayer} from "@deck.gl/carto";
import PropTypes from "prop-types";
import {MainDataContext} from "../../../main";
import {DateMode, EVENT_KEY_UPDATE_GRID_STATE, LOCAL_STORAGE_KEY_FILTER_MODEL} from "../../../../data/main/state";
import {filterModelToWheres} from "../../../../manager/filter";
import {First, One} from "../../../../manager/carto3";
import {DataFilterExtension} from "@deck.gl/extensions";
import {colorScale, rgbA} from "../../../../util";
import {MapDataContext} from "../index";
import _ from "lodash";
import {ConditionType} from "../../../../data/map/state";
import {MAP_LOCAL_STORAGE_KEY} from "../../../../data/map/reducer";


const ColorProfiles = {
    BAPC_TY: [
        [null, -0.001, [255, 75, 68]],
        [0.001, 1, [239, 243, 255]],
        [1, 3.548, [189, 215, 231]],
        [3.548, 13.124, [107, 174, 214]],
        [13.124, null, [34, 113, 181]],
    ],
    NSR_TY: [
        [null, -1, [255, 75, 68]],
        [1, 2664, [239, 243, 255]],
        [2664, 5472, [189, 215, 231]],
        [5472, 12120, [107, 174, 214]],
        [12120, null, [34, 113, 181]],
    ],
    GP_TY: [
        [null, -0.000001, [255, 75, 68]],
        [0.000001, 1257.7656, [239, 243, 255]],
        [1257.7656, 2584, [189, 215, 231]],
        [2584, 6313.5792, [107, 174, 214]],
        [6313.58, null, [34, 113, 181]],
    ],
    POPULATION: [
        [null, 10, [49, 54, 150]],
        [10, 100, [69, 117, 180]],
        [100, 1000, [117, 172, 208]],
        [1000, 2000, [170, 217, 233]],
        [2000, 3000, [225, 243, 248]],
        [3000, 4000, [254, 224, 144]],
        [4000, 5000, [253, 174, 97]],
        [5000, 7500, [244, 109, 67]],
        [7500, 100000, [215, 48, 39]],
        [100000, null, [165, 0, 38]],
    ]
}

export const colorClasses = [
    "#000000",
    "#898989",
    "#eff3ff",
    "#bdd7e7",
    "#6baed6",
    "#2271b5",
]

const UseRootMapSalesH3Layer = (props) => {

    const { state, setSelectedH3List } = useContext(MainDataContext)
    const { state: mapState } = useContext(MapDataContext)
    const lastFilterModel = useRef()
    const [tabelogH3s, setTabelogH3s] = useState()
    const [filterModel, setFilterModel] = useState()
    const [min, setMin] = useState()
    const [max, setMax] = useState()
    const [conditions, setConditions] = useState()

    useEffect(() => {
        if (!mapState.visibleConditionType) {
            setMin(null)
            setMax(null)
            return
        }

        let wheres = [`TRUE`]
        let values = []

        let col = `${mapState.visibleConditionType.replace("_TY", "")}${state.dateMode === DateMode.Monthly ? `_LAST_MONTH` : '_LAST_12_MONTHS'}`

        const sql = `
        SELECT
            MIN(${col}) AS MIN,
            MAX(${col}) AS MAX
        FROM ${process.env.REACT_APP_CARTO_TABLE_SALES_H3_VIEW}_${state.user.ID.toString().padStart(8, '0')} AS base
        WHERE ${wheres.join(' AND ')}
        `

        First(sql, values).then(row => {
            setMin(row.MIN)
            setMax(row.MAX)
        })
    }, [mapState.visibleConditionType, state.user, state.dateMode])


    useEffect(() => {
        window.addEventListener(EVENT_KEY_UPDATE_GRID_STATE, ()=>{
            const fm = localStorage.getItem(LOCAL_STORAGE_KEY_FILTER_MODEL)
            if (fm !== lastFilterModel.current) {
                lastFilterModel.current = fm
                setFilterModel(JSON.parse(fm))
            }
        })
    }, [])

    useEffect(() => {
        props.onLoadStart && props.onLoadStart()
    }, [filterModel, state.user, state.daeMode, state.conditions, mapState.visibleConditionType])

    const checkCount = useCallback(async (wheres, values) => {
        let cnt = await One(`
            SELECT COUNT(1) FROM ${process.env.REACT_APP_CARTO_TABLE_SALES_H3_VIEW}_${state.user.ID.toString().padStart(8, '0')} AS base
            WHERE ${wheres.join(' AND ')}
        `, values)
        return cnt > 0
    }, [state.user])

    const data = useMemo( async () => {
//        console.log("[H3]", "updated", state.startDate, state.endDate, conditions, mapState.visibleConditionType)
        if (!mapState.visibleConditionType) {
            setTimeout(() => {
                props.onLoadFinish()
            }, 1000)
            return null
        }

        let wheres = [`TRUE`]
        let wheres2 = [`TRUE`]
        let values = []

        const suffix = state.dateMode === DateMode.Monthly ? "LAST_MONTH" : "LAST_12_MONTHS"

        if (conditions) {
            // console.log('[SalesH3]', 'parse conditions', conditions)
            Object.entries(conditions).forEach(([field, cond]) => {
                if (cond) {
                    let col = `${field.replace("_TY", "")}_${suffix}`
                    wheres.push(`${col} BETWEEN :${values.length + 1} AND :${values.length + 2} + 1`)
                    values.push(cond[0])
                    values.push(cond[1])          

                    wheres2.push(`${field} BETWEEN :1 AND :2 + 1`)
                }
            })
        }

        if (!(await checkCount(wheres, values))) {
            setTimeout(() => {
                props.onLoadFinish()
            }, 1000)
            return null
        }

        const sql = `
        WITH data AS (
            SELECT 
                H3, 
                POPULATION_${suffix} AS POPULATION, 
                BAPC_${suffix} AS BAPC_TY,
                NSR_${suffix} AS NSR_TY,
                GP_${suffix} AS GP_TY
            FROM ${process.env.REACT_APP_CARTO_TABLE_SALES_H3_VIEW}_${state.user.ID.toString().padStart(8, '0')} AS base
        ),
        tiles AS (
            SELECT
                PERCENTILE_CONT(0.2) WITHIN GROUP ( ORDER BY CASE WHEN POPULATION > 0 THEN POPULATION END) AS POPULATION_P20,
                PERCENTILE_CONT(0.4) WITHIN GROUP ( ORDER BY CASE WHEN POPULATION > 0 THEN POPULATION END) AS POPULATION_P40,
                PERCENTILE_CONT(0.6) WITHIN GROUP ( ORDER BY CASE WHEN POPULATION > 0 THEN POPULATION END) AS POPULATION_P60,
                PERCENTILE_CONT(0.8) WITHIN GROUP ( ORDER BY CASE WHEN POPULATION > 0 THEN POPULATION END) AS POPULATION_P80,
                PERCENTILE_CONT(0.2) WITHIN GROUP ( ORDER BY CASE WHEN BAPC_TY > 0 THEN BAPC_TY END) AS BAPC_TY_P20,
                PERCENTILE_CONT(0.4) WITHIN GROUP ( ORDER BY CASE WHEN BAPC_TY > 0 THEN BAPC_TY END) AS BAPC_TY_P40,
                PERCENTILE_CONT(0.6) WITHIN GROUP ( ORDER BY CASE WHEN BAPC_TY > 0 THEN BAPC_TY END) AS BAPC_TY_P60,
                PERCENTILE_CONT(0.8) WITHIN GROUP ( ORDER BY CASE WHEN BAPC_TY > 0 THEN BAPC_TY END) AS BAPC_TY_P80,
                PERCENTILE_CONT(0.2) WITHIN GROUP ( ORDER BY CASE WHEN NSR_TY > 0 THEN NSR_TY END) AS NSR_TY_P20,
                PERCENTILE_CONT(0.4) WITHIN GROUP ( ORDER BY CASE WHEN NSR_TY > 0 THEN NSR_TY END) AS NSR_TY_P40,
                PERCENTILE_CONT(0.6) WITHIN GROUP ( ORDER BY CASE WHEN NSR_TY > 0 THEN NSR_TY END) AS NSR_TY_P60,
                PERCENTILE_CONT(0.8) WITHIN GROUP ( ORDER BY CASE WHEN NSR_TY > 0 THEN NSR_TY END) AS NSR_TY_P80,
                PERCENTILE_CONT(0.2) WITHIN GROUP ( ORDER BY CASE WHEN GP_TY > 0 THEN GP_TY END) AS GP_TY_P20,
                PERCENTILE_CONT(0.4) WITHIN GROUP ( ORDER BY CASE WHEN GP_TY > 0 THEN GP_TY END) AS GP_TY_P40,
                PERCENTILE_CONT(0.6) WITHIN GROUP ( ORDER BY CASE WHEN GP_TY > 0 THEN GP_TY END) AS GP_TY_P60,
                PERCENTILE_CONT(0.8) WITHIN GROUP ( ORDER BY CASE WHEN GP_TY > 0 THEN GP_TY END) AS GP_TY_P80
            FROM data
        )
        SELECT
            H3,
            POPULATION,
            BAPC_TY,
            NSR_TY,
            GP_TY,
            CASE
                WHEN POPULATION IS NULL THEN 0
                WHEN POPULATION < POPULATION_P20 THEN 1
                WHEN POPULATION < POPULATION_P40 THEN 2
                WHEN POPULATION < POPULATION_P60 THEN 3
                WHEN POPULATION < POPULATION_P80 THEN 4
                ELSE 5
            END AS POPULATION_CLASS,
            CASE
                WHEN BAPC_TY IS NULL THEN 0
                WHEN BAPC_TY < BAPC_TY_P20 THEN 1
                WHEN BAPC_TY < BAPC_TY_P40 THEN 2
                WHEN BAPC_TY < BAPC_TY_P60 THEN 3
                WHEN BAPC_TY < BAPC_TY_P80 THEN 4
                ELSE 5
            END AS BAPC_TY_CLASS,
            CASE
                WHEN NSR_TY IS NULL THEN 0
                WHEN NSR_TY < NSR_TY_P20 THEN 1
                WHEN NSR_TY < NSR_TY_P40 THEN 2
                WHEN NSR_TY < NSR_TY_P60 THEN 3
                WHEN NSR_TY < NSR_TY_P80 THEN 4
                ELSE 5
            END AS NSR_TY_CLASS,     
            CASE
                WHEN GP_TY IS NULL THEN 0
                WHEN GP_TY < GP_TY_P20 THEN 1
                WHEN GP_TY < GP_TY_P40 THEN 2
                WHEN GP_TY < GP_TY_P60 THEN 3
                WHEN GP_TY < GP_TY_P80 THEN 4
                ELSE 5
            END AS GP_TY_CLASS
        FROM data, tiles
        WHERE ${wheres2.join(' AND ')}
        `
        // console.log('[SalesH3]', 'query', sql, values)

        return h3QuerySource({
            accessToken: process.env.REACT_APP_CARTO_ACCESS_TOKEN,
            connectionName: 'snowflakeconn',
            apiBaseUrl: 'https://gcp-asia-northeast1.api.carto.com',
            sqlQuery: sql,
            queryParameters: values,
            aggregationExp: `
            MIN(H3) AS h3sa,
            ARRAY_AGG(H3) AS h3s,
            AVG(POPULATION) AS POPULATION,
            AVG(BAPC_TY) AS BAPC_TY,
            AVG(NSR_TY) AS NSR_TY,
            AVG(GP_TY) AS GP_TY,
            MIN(POPULATION_CLASS) AS POPULATION_CLASS,
            MIN(BAPC_TY_CLASS) AS BAPC_TY_CLASS,
            MIN(NSR_TY_CLASS) AS NSR_TY_CLASS,
            MIN(GP_TY_CLASS) AS GP_TY_CLASS
        `,
            aggregationResLevel: 7,
        })
    }, [filterModel, state.user, state.dateMode, conditions, mapState.visibleConditionType])

    const getConditionLevels = useCallback((wheres) => {

    }, [])

    const updateConditions = _.debounce((cond) => {
        setConditions(cond)
    }, 500)

    useEffect(() => {
        updateConditions(state.conditions)
    }, [state.conditions])

    const getFilterValue = useCallback(d => {
        // console.log("[SalesH3]", "filter", d.properties, d.properties[mapState.visibleConditionType])
        return (_.isNil(d.properties[mapState.visibleConditionType]) || d.properties[mapState.visibleConditionType]) === 0 ? 0: 1
    }, [mapState.visibleConditionType])

    const getLineColor = useCallback((p) => {
        if (state.selectedH3List && p.properties["H3S"].split(",").find(h => state.selectedH3List.includes(h))) {
            return [255,0,0, 204]
        }
        return [221, 178, 124, 204]
    }, [state.selectedH3List])

    const getFillColor = useCallback((p, v) => {

        const classNum = parseInt(p.properties[`${mapState.visibleConditionType}_CLASS`])
        const color = rgbA(colorClasses[classNum], (classNum === 0) ? 0.0 : mapState.visibleConditionOpacity)
        return color
    }, [min, max, mapState.visibleConditionType, conditions, mapState.visibleConditionOpacity])

    const getLineWidth = useCallback((p) => {
        if (state.selectedH3List && p.properties["H3S"].split(",").find(h => state.selectedH3List.includes(h))) {
            return 2
        }
        return 1
    }, [state.selectedH3List])

    return new H3TileLayer({
        id: 'h3-hexagon-layer',
        data,
        getFillColor,
        getLineColor,
        getLineWidth,
        getFilterValue,
        lineWidthUnits: 'pixels',
        stroked: true,
        extruded: false,
        pickable: true,
        onClick: props.onClick,
        filterRange: [1, 1],
        updateTriggers: {
            getFillColor: [min, max, mapState.visibleConditionType, conditions, mapState.visibleConditionOpacity],
            getLineColor: [state.selectedH3List],
            getLineWidth: [state.selectedH3List],
            getFilterValue: [mapState.visibleConditionType],
        },
        extensions: [new DataFilterExtension({filterSize: 1})],
        loadOptions: {
            fetch: {
                headers: {
                    "Cache-control": "max-age=300"
                }    
            }
        },
        onDataLoad: e => {
            console.log("[Sales]", "layer data loaded")
        },
        onViewportLoad: e => {
            props.onLoadFinish && props.onLoadFinish()
        },
    })
}

UseRootMapSalesH3Layer.propTypes = {
    onClick: PropTypes.func,
    onLoadStart: PropTypes.func,
    onLoadFinish: PropTypes.func,
}

export default UseRootMapSalesH3Layer
