import dayjs from "dayjs"
import { Rows } from "./carto3"
import filter from "../views/root/filter";
import { ColumnDefs } from "../views/root/list/column"
import {useCallback, useMemo, useState} from "react";

export const filterModelToWheres = (filterModel) => {
  if (!filterModel) { return ["TRUE"]}
  let val = Object.entries(filterModel).map(([name, filter]) => {
    return filterModelToWhereString(name, filter)
  })
  if (val.length === 0) { return ["TRUE"]}
  return val
}

export const filterModelToWhereStringArray = (name, filterModel) => {
  let val = filterModelToWhereString(name, filterModel)
  return val ? [val] : []
}

export const filterModelToWhereString = (name, filterModel) => {
  switch (filterModel.filterType) {
    case "text":
      return textFilterModelToWhereString(name, filterModel)
    case "number":
      return numberFilterModelToWhereString(name, filterModel)
    case "set":
      return setFilterModelToWhereString(name, filterModel)
    case "date":
      return dateFilterModelToWhereString(name, filterModel)
    default:
      break
  }
  return null
}

export const numberDateFilterModelToWhereString = (name, filterModel) => {
  if (filterModel.conditions) {
    let wheres = []
    filterModel.conditions.forEach(cond => {
      wheres.push(numberDateFilterModelToWhereString(name, cond))
    })
    return `(${wheres.join(` ${filterModel.operator}` )})`
  }
  let ymd = dayjs(filterModel.dateFrom).format("YYYYMMDD")
  if (!ymd) {
    return null
  }
  let ymdTo = filterModel.dateTo && dayjs(filterModel.dateTo).format("YYYYMMDD")
  switch (filterModel.type) {
    case "equals":
      return `${name} = ${ymd}`
    case "notEquals":
      return `${name} <> ${ymd}`
    case "lessThan":
      return `${name} < ${ymd}`
    case "lessThanOrEqual":
      return `${name} <= ${ymd}`
    case "greaterThan":
      return `${name} > ${ymd}`
    case "greaterThanOrEqual":
      return `${name} >= ${ymd}`
    case "inRange":
      return `${name} BETWEEN ${ymd} AND ${ymdTo}`
    default:
      break
  }
  return null
}

export const setFilterModelToWhereString = (name, filterModel) => {

  const def = ColumnDefs.find(f => f.field === name)
//  console.log(def, name, filterModel)

  if (def.updateFilterModel) {
    filterModel.values = filterModel.values.map(value => {
      return def.updateFilterModel({value})
    })
  }

  if (filterModel.values.length === 0) {
    return "FALSE"
  }
  if (filterModel.values && filterModel.values.length > 0) {
    let isEmpty = false
    let values = filterModel.values
    if (values.includes("(空白)")) {
      values.splice(values.indexOf("(空白)"), 1)
      isEmpty = true
    }
    if (values.includes("(ブランク)")) {
      values.splice(values.indexOf("(ブランク)"), 1)
      isEmpty = true
    }
    return (
        `(${name} IN ('${filterModel.values.join("','")}')` +
        (isEmpty ? ` OR ${name} IS NULL)` : ")")
    )
  }

  return null
}

export const dateFilterModelToWhereString = (name, filterModel) => {
  if (filterModel.conditions) {
    let wheres = []
    filterModel.conditions.forEach(cond => {
      wheres.push(dateFilterModelToWhereString(name, cond))
    })
    return `(${wheres.join(` ${filterModel.operator} `)})`
  }

  if (!filterModel.dateFrom || filterModel.dateFrom.indexOf("'") !== -1) {
    return null
  }
  if (filterModel.dateTo && filterModel.dateTo.indexOf("'") !== -1) {
    return null
  }

  switch (filterModel.type) {
    case "equals":
      return `${name}::date = '${filterModel.dateFrom}'::date`
    case "notEquals":
      return `${name}::date <> '${filterModel.dateFrom}'::date`
    case "lessThan":
      return `${name}::date < '${filterModel.dateFrom}'::date`
    case "greaterThan":
      return `${name}::date > '${filterModel.dateFrom}'::date`
    case "inRange":
      return `${name}::date BETWEEN '${filterModel.dateFrom}'::date AND '${filterModel.dateTo}'::date`
    default:
      break
  }

  return null
}

export const numberFilterModelToWhereString = (name, filterModel) => {
  if (filterModel.conditions) {
    let wheres = []
    filterModel.conditions.forEach(cond => {
      wheres.push(numberFilterModelToWhereString(name, cond))
    })
    return `(${wheres.join(` ${filterModel.operator} `)})`
  }
  if (filterModel.filter.toString().indexOf("'") !== -1) {
    // シングルクォーテーションを含む場合は処理しない
    return null
  }
  switch (filterModel.type) {
    case "equals":
      return `${name} = ${filterModel.filter}`
    case "notEquals":
      return `${name} <> ${filterModel.filter}`
    case "lessThan":
      return `${name} < ${filterModel.filter}`
    case "lessThanOrEqual":
      return `${name} <= ${filterModel.filter}`
    case "greaterThan":
      return `${name} > ${filterModel.filter}`
    case "greaterThanOrEqual":
      return `${name} >= ${filterModel.filter}`
    case "inRange":
      return `${name} BETWEEN ${filterModel.filter} AND ${filterModel.filterTo}`
    default:
      break
  }
  return null
}

export const textFilterModelToWhereString = (name, filterModel) => {
  if (filterModel.conditions) {
    let wheres = []
    filterModel.conditions.forEach(cond => {
      wheres.push(textFilterModelToWhereString(name, cond))
    })
    return `(${wheres.join(` ${filterModel.operator} `)})`
  }
  if (filterModel.filter.toString().indexOf("'") !== -1) {
    // シングルクォーテーションを含む場合は処理しない
    return null
  }
  switch (filterModel.type) {
    case "contains":
      return `${name} LIKE '%${filterModel.filter}%'`
    case "notContains":
      return `${name} NOT LIKE '%${filterModel.filter}%'`
    case "equals":
      return `${name} = '${filterModel.filter}'`
    case "notEqual":
      return `${name} <> '${filterModel.filter}'`
    case "startsWith":
      return `${name} LIKE '${filterModel.filter}%'`
    case "endsWith":
      return `${name} LIKE '%${filterModel.filter}'`
    default:
      break
  }
  return null
}

export const filterModelToMatchProperty = (properties, filterModel) => {
  for(let [field, filter] of Object.entries(filterModel)) {
    switch (filter.filterType) {
      case "text":
        if (!textFilterModelToMatchProperty(properties[field], filter)) {
          return false
        }
        break
      case "number":
        if (!numberFilterModelToMatchProperty(properties[field], filter)) {
          return false
        }
        break
      case "set":
        if (!setFilterModelToMatchProperty(properties[field], filter)) {
          return false
        }
        break
      case "date":
        if (!dateFilterModelToMatchProperty(properties[field], filter)) {
          return false
        }
        break
      default:
        break
    }
  }
  return true
}

export const textFilterModelToMatchProperty = (value, model) => {
  if (model.conditions) {
    let vals = []
    model.conditions.forEach(cond => {
      vals.push(textFilterModelToMatchProperty(value, cond))
    })
    switch(model.operator) {
      case "AND":
        return !vals.includes(false)
      case "OR":
        return vals.includes(true)
      default:
        break
    }
    return false
  }
  switch (model.type) {
    case "contains":
      return value.includes(model.filter)
    case "notContains":
      return !value.includes(model.filter)
    case "equals":
      return value === model.filter
    case "notEqual":
      return value !== model.filter
    case "startsWith":
      return value.startsWith(model.filter)
    case "endsWith":
      return value.endsWith(model.filter)
    default:
      break
  }
  return true
}

export const numberFilterModelToMatchProperty = (value, model) => {
  if (model.conditions) {
    let vals = []
    model.conditions.forEach(cond => {
      vals.push(numberFilterModelToMatchProperty(value, cond))
    })
    switch(model.operator) {
      case "AND":
        return !vals.includes(false)
      case "OR":
        return vals.includes(true)
      default:
        break
    }
    return false
  }
  switch (model.type) {
    case "equals":
      return value === model.filter
    case "notEquals":
      return value !== model.filter
    case "lessThan":
      return value < model.filter
    case "lessThanOrEqual":
      return value <= model.filter
    case "greaterThan":
      return value > model.filter
    case "greaterThanOrEqual":
      return value >= model.filter
    case "inRange":
      return value >= model.filter && value <= model.filterTo
    default:
      break
  }
  return true
}

export const setFilterModelToMatchProperty = (value, model) => {
  if (model.values.length === 0) {
    return false
  }
  if (model.values && model.values.length > 0) {
    let isEmpty = false
    let values = model.values
    if (values.includes("(空白)")) {
      values.splice(values.indexOf("(空白)"), 1)
      isEmpty = true
    }
    if (isEmpty && (value === '' || value === undefined || value === null)) { return true }
    return model.values.includes(value)
  }

  return true
}

export const dateFilterModelToMatchProperty = (value, model) => {
  if (model.conditions) {
    let vals = []
    model.conditions.forEach(cond => {
      vals.push(dateFilterModelToMatchProperty(value, cond))
    })
    switch(model.operator) {
      case "AND":
        return !vals.includes(false)
      case "OR":
        return vals.includes(true)
      default:
        break
    }
    return false
  }

  if (!model.dateFrom || model.dateFrom.indexOf("'") !== -1) {
    return false
  }
  if (model.dateTo && model.dateTo.indexOf("'") !== -1) {
    return false
  }

  switch (model.type) {
    case "equals":
      return dayjs(value).diff(dayjs(model.dateFrom), "day") === 0
    case "notEquals":
      return dayjs(value).diff(dayjs(model.dateFrom), "day") !== 0
    case "lessThan":
      return dayjs(value).diff(dayjs(model.dateFrom), "day") > 0
    case "greaterThan":
      return dayjs(value).diff(dayjs(model.dateFrom), "day") < 0
    case "inRange":
      return dayjs(value).diff(dayjs(model.dateFrom), "day") >= 0 &&
          dayjs(value).diff(dayjs(model.dateTo), "day") <= 0
    default:
      break
  }

  return true
}

export const booleanFilterParams = {
  values: [false, true],
  valueFormatter: (param) => {
    if (param.value === true) { return "チェック"}
    if (param.value === false) { return "未チェック"}
    return "(ブランク)"
  }
}


export const UseDistinctFilterParams = () => {

  const [dic, setDic] = useState({})

  const values = useCallback((params) => {
    if (Object.keys(dic).includes(params.colDef.field)) {
      return params.success(dic[params.colDef.field])
    }
    if (!params.colDef.field) {
      return params.success([])
    }

    let wheres = ["TRUE"]
    const selectedTabelogIds = params.colDef.filterParams.selectedTabelogIds
    if (selectedTabelogIds !== undefined && selectedTabelogIds.length > 0) {
      wheres.push(`TABELOG_ID IN ('${selectedTabelogIds.join("','")}')`)
    }

    Rows(`
    SELECT DISTINCT ${params.colDef.field} AS key
    FROM ${process.env.REACT_APP_CARTO_TABLE_TABELOG_POINT}
    WHERE ${wheres.join(" AND ")}
    ORDER BY 1
    `)
        .then(rows => {
          const vals = rows.map(row => row.KEY)
          setDic(prev =>{ return {...prev, [params.colDef.field]: vals} })
          params.success(vals)
        })
        .catch(params.fail)
  }, [dic])

  return {
    values,
    refreshValuesOnOpen: true,
    _type: "distinct",
  }

}

export const distinctFilterParams = {
  values: (params) => {
    console.log("[DistinctFilterParams]", "load", params)

    let wheres = ["TRUE"]

    if (params.colDef.field) {
      let selcols = [params.colDef.field]
      let order = params.colDef.field
      Rows(
          `SELECT DISTINCT ${selcols.join(",")} FROM ${
              process.env.REACT_APP_CARTO_TABLE_TABELOG_POINT
          }
        WHERE ${wheres.join(" AND ")}
        ORDER BY ${order}`
      )
          .then(rows => {
            let values = ["(空白)"].concat(
                rows.flatMap((v) => {
                  if (v[params.colDef.field]) {
                    return [v[params.colDef.field]]
                  } else {
                    return []
                  }
                })
            )
            params.success(values)
          })
          .catch(() => {
            params.success([])
          })
    }
    params.success([])
  },
  refreshValuesOnOpen: true,
}

export const filterModelToValueString = (model) => {

  switch (model.filterType) {
    case "text":
      return textFilterModelToValueString(model)
    case "number":
      return numberFilterModelToValueString(model)
    case "set":
      return setFilterModelToValueString(model)
    case "date":
      return dateFilterModelToValueString(filter)
    default:
      break
  }

  return null
}

const textFilterModelToValueString = (model) => {
  if (model.conditions) {
    let vals = []
    model.conditions.forEach(cond => {
      vals.push(textFilterModelToValueString(cond))
    })
    let type = model.operator === "AND" ? "及び" : "又は"
    return `${vals.join(` ${type} `)}`
  }

  if (!model.filter) {
    return "なし"
  }

  switch (model.type) {
    case "contains":
      return `「${model.filter}」を含む`
    case "notContains":
      return `「${model.filter}」を含まない`
    case "equals":
      return `「${model.filter}」と一致`
    case "notEqual":
      return `「${model.filter}」で不一致`
    case "startsWith":
      return `「${model.filter}」から始まる`
    case "endsWith":
      return `「${model.filter}」で終わる`
    default:
      break
  }

  return null
}

const numberFilterModelToValueString = (model) => {
  if (model.conditions) {
    let vals = []
    model.conditions.forEach(cond => {
      vals.push(numberFilterModelToValueString(cond))
    })
    let type = model.operator === "AND" ? "及び" : "又は"
    return `${vals.join(` ${type} `)}`
  }

  if (!model.filter) {
    return "なし"
  }

  switch (model.type) {
    case "equals":
      return `${model.filter}に一致`
    case "notEquals":
      return `${model.filter}に不一致`
    case "lessThan":
      return `${model.filter}未満`
    case "lessThanOrEqual":
      return `${model.filter}以下`
    case "greaterThan":
      return `${model.filter}超過`
    case "greaterThanOrEqual":
      return `${model.filter}以上`
    case "inRange":
      return `${model.filter}〜${model.filterTo}`
    default:
      break
  }

  return null
}

const setFilterModelToValueString = (model) => {

  if ((model.values?.length ?? 0) === 0) {
    return "なし"
  }

  if (model.values.length > 3) {
    return `${model.values.length}個選択中`
  }

  return model.values.map(v => `「${v ?? '(ブランク)'}」`).join(', ')
}

const dateFilterModelToValueString = (model) => {
  if (model.conditions) {
    let vals = []
    model.conditions.forEach(cond => {
      vals.push(numberFilterModelToValueString(cond))
    })
    let type = model.operator === "AND" ? "及び" : "又は"
    return `${vals.join(` ${type} `)}`
  }

  if (!model.dateFrom) {
    return "なし"
  }

  const d = dayjs(model.dateFrom).format("YYYY-MM-DD")

  switch (model.type) {
    case "equals":
      return `「${d}」に一致`
    case "notEquals":
      return `「${d}」に不一致`
    case "lessThan":
      return `「${d}」より前`
    case "greaterThan":
      return `「${d}」より後`
    case "inRange":
      return `「${d}」〜「${dayjs(model.dateTo).format("YYYY-MM-DD")}」`
    default:
      break
  }

  return null
}

export const filterParamsConvertKeys = {
  "distinct": distinctFilterParams,
  "boolean": booleanFilterParams,
}


