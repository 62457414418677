import {useReducer} from "react"
import initialState from "./state"
import reducer, {ActionType} from "./reducer"

const UseMapData = () => {

    const [state, dispatch] = useReducer(reducer, {
        ...initialState
    })

    const setSelectedConditionType = (value) => {
        dispatch({type: ActionType.SetSelectedConditionType, value})
    }

    const toggleSelectedConditionType = (value) => {
        dispatch({type: ActionType.ToggleSelectedConditionType, value})
    }

    const setVisibleConditionType = (value) => {
        dispatch({type: ActionType.SetVisibleConditionType, value})
    }

    const toggleVisibleConditionType = (value) => {
        dispatch({type: ActionType.ToggleVisibleConditionType, value})
    }

    const setShowPointLayer = (value) => {
        dispatch({type: ActionType.SetShowPointLayer, value})
    }

    const setVisibleConditionOpacity = (value) => {
        dispatch({type: ActionType.SetVisibleConditionOpacity, value})
    }

    const setMapStyle = (value) => {
        dispatch({type: ActionType.SetMapStyle, value})
    }

    const setTabelogInfo = (value) => {
        dispatch({type: ActionType.SetTabelogInfo, value})
    }

    const setVisibleConditionLevels = (value) => {
        dispatch({type: ActionType.SetVisibleConditionLevels, value})
    }

    return {
        state,
        setSelectedConditionType,
        toggleSelectedConditionType,
        setVisibleConditionType,
        toggleVisibleConditionType,
        setShowPointLayer,
        setVisibleConditionOpacity,
        setMapStyle,
        setTabelogInfo,
        setVisibleConditionLevels,
    }
}

export default UseMapData
