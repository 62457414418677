import {Button, IconButton} from "@mui/material";
import {OpenInNew as OpenInNewIcon} from "@mui/icons-material";

export const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
    resizable: true,
    includeBlanksInEquals: false,
}

const valueGetterForNumberBoolean = (p) => {
    if (!p.data) { return null }
    if (!Object.keys(p.data).includes(p.colDef.field)) { return null }
    if (p.data[p.colDef.field] === null || p.data[p.colDef.field] === undefined) { return null }
    return !!p.data[p.colDef.field]
}

const updateFilterModelForNumberBoolean = ({value}) => {
    switch(value) {
        case 'true':
            return 1
        case 'false':
            return 0
        case null:
            return '(空白)'
        default:
            break
    }
    return null
}

export const ColumnDefs = [
    {
        headerName: "No",
        valueGetter: e => (e.node?.rowIndex === null || e.node?.rowIndex === undefined) ? null: e.node.rowIndex + 1,
        width: 70,
        cellStyle: {textAlign: "right"},
        filter: false,
        pinned: true,
    },
    {
        headerName: '選択',
        field: 'selected',
        width: 88,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: false,
        floatingFilter: false,
        pinned: true,
    },
    {
        field: "IS_ASSIGNED",
        headerName: "担当登録",
        cellDataType: "boolean",
        filterParams: {
            values: [true, false],
            valueFormatter: (p) => {
                if(p.value === true) { return "登録済み"}
                if(p.value === false) { return "未登録"}
                return "(ブランク)"
            }
        },
        width: 112,
        pinned: true,
    },
    {
        field: "ASSIGNED_SALES_ID",
        headerName: "担当者ID",
        hide: true,
        width: 200,
    },
    {
        field: "ASSIGNED_SALES_NAME",
        headerName: "担当者名",
        filterParams: "distinct",
        width: 112,
    },
    {
        field: "SALES_STAGE",
        headerName: "商談ステータス",
        filterParams: "distinct",
        width: 160,
        hide: true
    },
    {
        field: "HANA_MATCH_FLAG",
        headerName: "既存店フラグ(HANA)",
        filterParams: "distinct",
        width: 181,
    },
    {
        field: "NSR_PRED",
        headerName: "NSR優先",
        filterParams: "distinct",
        width: 121,
    },
    {
        field: "SAKAYA_MATCH_FLAG",
        headerName: "既存店フラグ(酒屋)",
        filterParams: "distinct",
        width: 170,
    },
    {
        field: "SAKAYA_NAME",
        headerName: "酒屋様名",
        filter: "agTextColumnFilter",
        width: 150,
        hide: true
    },
    {
        field: "HANA_DEALER_CODE",
        headerName: "HANAディーラーコード",
        filter: "agTextColumnFilter",
        width: 200,
        hide: true
    },
    {
        field: "TABELOG_ID",
        headerName: "ID",
        filter: "agNumberColumnFilter",
        width: 120,
    },
    {
        field: "RST_TITLE",
        headerName: "食べログ名称",
        filter: "agTextColumnFilter",
        width: 286,
    },
    {
        field: "RST_URL",
        headerName: "食べログリンク",
        cellRenderer: (e) => {
            if (!e.value) { return null }
            console.log("[TABELOG]", "Link", e)
            return (
                <IconButton size="small" onClick={() => {
                    window.open(e.value)
                }}>
                    <OpenInNewIcon style={{fontSize: "16px"}} />
                </IconButton>
            )
        },
        width: 150,
        suppressCellFocus: true,
        suppressCellSelection: true,
    },
    {
        field: "PREFECTURE",
        headerName: "食べログ都道府県",
        filterParams: "distinct",
        width: 160,
    },
    {
        field: "CITY",
        headerName: "食べログ市・区",
        filterParams: "distinct",
        width: 150,
    },
    {
        field: "CUISINE_CAT_1",
        headerName: "食べログ業態(全部)",
        filterParams: "distinct",
        width: 170,
    },
    {
        field: "IS_FAMILY_FRIENDLY",
        headerName: "食べログ家族",
        width: 140,
        cellDataType: "boolean",
        filterParams: "boolean",
    },
    {
        field: "IS_FRIEND_FRIENDLY",
        headerName: "食べログ友人",
        width: 140,
        cellDataType: "boolean",
        filterParams: "boolean",
    },
    {
        field: "IS_ALONE_FRIENDLY",
        headerName: "食べログ一人",
        width: 140,
        cellDataType: "boolean",
        filterParams: "boolean",
    },
    {
        field: "ACCESS_METER_MIN",
        headerName: "食べログ　最寄駅からの最小距離(m)",
        filter: "agNumberColumnFilter",
        width: 270,
    },
    {
        field: "ACCESS_METER_MAX",
        headerName: "食べログ　最寄駅からの最大距離(m)",
        filter: "agNumberColumnFilter",
        width: 270,
    },
    {
        field: "LUNCH_PRICE",
        headerName: "食べログ　昼ごはんの価格",
        filter: "agNumberColumnFilter",
        width: 210,
    },
    {
        field: "DINNER_PRICE",
        headerName: "食べログ　夜ごはんの価格",
        filter: "agNumberColumnFilter",
        width: 210,
    },
    {
        field: "RATING_CNT",
        headerName: "食べログコロミ数",
        filter: "agNumberColumnFilter",
        width: 160,
    },
    {
        field: "RATING_SCORE",
        headerName: "食べログコロミスコア",
        filter: "agNumberColumnFilter",
        width: 190,
    },
    {
        field: "HAS_HOME_PAGE_URL",
        headerName: "食べログホームページ",
        cellDataType: "boolean",
        filterParams: "boolean",
        width: 190,
    },
    {
        field: "DAYS_OPEN_WEEK",
        headerName: "食べログ営業日（平日）",
        filter: "agNumberColumnFilter",
        width: 200,
    },
    {
        field: "DAYS_OPEN_WEEKEND",
        headerName: "食べログ営業日（週末）",
        filter: "agNumberColumnFilter",
        width: 200,
    },
    {
        field: "CASH_ONLY",
        headerName: "食べログ支払方法",
        cellDataType: "boolean",
        filterParams: "boolean",
        width: 160,
    },
    {
        field: "NUM_SEATS",
        headerName: "食べログ席数",
        filter: "agNumberColumnFilter",
        width: 140,
    },
    {
        field: "NO_SMOKING",
        headerName: "食べログ禁煙/喫煙",
        cellDataType: "boolean",
        filterParams: "boolean",
        width: 170,
    },
    {
        field: "HAS_PARKING",
        headerName: "食べログ駐車場",
        cellDataType: "boolean",
        filterParams: "boolean",
        width: 150,
    },
    {
        field: "MONTHS_SINCE_OPEN",
        headerName: "食べログオープン日",
        filter: "agNumberColumnFilter",
        width: 175,
    },
    {
        field: "HAS_TABEHODAI",
        headerName: "食べログコースメニュー",
        cellDataType: "boolean",
        filterParams: "boolean",
        width: 200,
    },
    {
        field: "HAS_NOMIHODAI",
        headerName: "食べログコースメニュー",
        cellDataType: "boolean",
        filterParams: "boolean",
        width: 200,
    },
    {
        field: "LATITUDE",
        headerName: "食べログ緯度",
        cellDataType: "number",
        hide: true,
    },
    {
        field: "LONGITUDE",
        headerName: "食べログ経度",
        cellDataType: "number",
        hide: true,
    },
    {
        field: "POPULATION_LAST_MONTH",
        headerName: "前月の人口",
        filter: "agNumberColumnFilter",
        width: 175,
    },
    {
        field: "POPULATION_LAST_12_MONTHS",
        headerName: "過去12ヶ月の人口",
        filter: "agNumberColumnFilter",
        width: 175,
    },

]

