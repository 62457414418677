export const LOCAL_STORAGE_KEY = "__main_state__"
export const LOCAL_STORAGE_KEY_FILTER_MODEL = "_filter_model"
export const LOCAL_STORAGE_KEY_COLUMN_STATE = "_column_state"
export const EVENT_KEY_UPDATE_GRID_STATE = "update-grid-storage"
export const LOCAL_STORAGE_KEY_COLUMN_DEFS = "_column_defs"

export const ViewMode = {
    Map: "map",
    List: "list",
    Graph: "graph",
}

export const ViewDirection = {
    Vertical: "vertical",
    Horizontal: "horizontal",
}

export const DataType = {
    TABELOG: "tabelog",
    SALES: "sales"
}

export const DateMode = {
    Monthly: "monthly",
    Yearly: "yearly",
}

const MainState = {

    user: null,
    viewMode: [ViewMode.Map, ViewMode.List],
    filterModel: null,
    filterModelSales: null,
    viewDirection: ViewDirection.Vertical,
    separatorRate: 0.5,
    selectedH3List: [],
    listH3List: [],
    selectedTabelogIds: [],
    filter: null,
    conditions: {},
    sessionStartTime: null,
    showSelectedOnly: false,
    mapViewBounds: null,
    graphData: null,
    isDragging: null,
    mapViewState: null,
    ...(localStorage.getItem(LOCAL_STORAGE_KEY) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) : {}),
    dataType: DataType.TABELOG,
    dateMode: DateMode.Monthly,
    tabelogInfo: null,
    salesInfo: null,
    selectedList: null,
    tabelogFilterModel: null,
    salesFilterModel: null,
    removeFilter: null,
    showTabelogPointType: null,
 }

export default MainState
