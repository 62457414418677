import React, {useCallback, useContext, useEffect, useMemo, useState} from "react"
import {Box, Button, TextField, Typography} from "@mui/material";
import {First} from "../../manager/carto3";
import _ from "lodash";
import {MainDataContext} from "../main";

const styles = {
    root: {
        margin: '0',
        padding: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
    },
    baseBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        width: '300px',
    },
    titleBox: {
        textAlign: "center",
    },
    titleText: {

    },
    idTextField: {

    },
    passwordTextField: {

    },
    buttonBox: {
        textAlign: "center",
    },
    loginButton: {

    }
}

const LoginView = (props) => {

    const { setUser, setSessionStartTime, setMapViewState, setShowTabelogPointType } = useContext(MainDataContext)
    const [id, setId] = useState()
    const [password, setPassword] = useState()
    const [isError, setIsError] = useState(false)
    const [hash, setHash] = useState()

    useEffect(() => {
        if (_.isEmpty(password)) {
            setHash(null)
        }
        const encoder = new TextEncoder()
        const uint8 = encoder.encode(password)
        crypto.subtle.digest('SHA-256', uint8)
            .then(buf => {
                const arr = Array.from(new Uint8Array(buf))
                const hex = arr.map(b => b.toString(16).padStart(2, '0')).join('')
                setHash(hex)
            })
    }, [password])

    const onLogin = useCallback(() => {
        console.log("id:pass:hash", id, password, hash)
        First(`
        SELECT ID,NAME,MAIL,H3_INDICES_LEVEL11,GEOMETRY FROM ${process.env.REACT_APP_CARTO_TABLE_SALES}
        WHERE ID = :1 AND PASSWORD = :2
        `, [
            id,
            hash,
        ]).then(user => {
            if (!user) {
                isError(true)
                return
            }
            setUser(user)
            setSessionStartTime(Date.now())
            setMapViewState(null)
            setShowTabelogPointType(null)
        }).catch(e => {
            setIsError(true)
        })
    }, [id, hash])

    const onChangeId = e => {
        setIsError(false)
        setId(e.target.value)
    }

    const onChangePassword = e => {
        setIsError(false)
        setPassword(e.target.value)
    }

    return (
        <Box style={styles.root}>
            <Box style={styles.baseBox}>
                <Box style={styles.titleBox}>
                    <Typography style={styles.titleText}>コカ・コーラ・販売管理</Typography>
                </Box>
                {isError && <Typography size="small" style={{color: "#f00"}}>社員番号かパスワードが間違っています</Typography>}
                <TextField value={id} onChange={onChangeId} size="small" label="社員番号" style={styles.idTextField} variant="outlined"></TextField>
                <TextField value={password} onChange={onChangePassword} size="small" label="パスワード" type="password" style={styles.passwordTextField} variant="outlined"></TextField>
                <Box style={styles.buttonBox}>
                    <Button style={styles.loginButton} variant="contained" onClick={onLogin}>ログイン</Button>
                </Box>
            </Box>
        </Box>
    )

}

export default LoginView
