import chroma from "chroma-js";
import React, {useCallback, useEffect, useRef, useState} from "react";

export const rgbA = (hex, opacity = null) => {

    hex = hex.replace(/^#/, '')

    if (hex.length === 3) {
        hex = hex.split('').map(c => `${c}${c}`).join('')
    }

    let alpha
    if (opacity !== null) {
        alpha = Math.round(opacity * 255).toString(16).padStart(2, "0")
    } else {
        alpha = hex.length >= 8 ? hex.substring(6, 8) : "ff"
    }
    hex = `${hex.substring(0, 6)}${alpha}`

    return [
        parseInt(hex.substring(0, 2), 16),
        parseInt(hex.substring(2, 4), 16),
        parseInt(hex.substring(4, 6), 16),
        parseInt(hex.substring(6, 8), 16),
    ]

}

export const colorScale = (value, min, max, opacity = 1.0) => {
    const purpleColor = '#800080'
    const redColor = '#F33'
    const lightRedColor = '#FCC'
    const whiteColor = '#FFF'
    const lightBlueColor = '#CCF'
    const blueColor = '#00F'

    const negativeScale = chroma.scale([purpleColor, redColor, lightRedColor, whiteColor])
        .domain([min, min / 3, 0])

    const positiveScale = chroma.scale([whiteColor, lightBlueColor, blueColor])
        .domain([0, max / 2, max])

    let color
    if (value < 0) {
        color = negativeScale(value)
    } else if (value > 0) {
        color = positiveScale(value)
    } else {
        color = chroma(whiteColor)
    }

    return [...color.rgb().map(v => Math.round(v)), Math.round(opacity * 255)]
}
