import {DataType} from "../main/state";

export const ActionType = {
    // selectedConditionType
    SetSelectedConditionType: "SET_SELECTED_CONDITION_TYPE",
    ToggleSelectedConditionType: "TOGGLE_SELECTED_CONDITION_TYPE",
    SetVisibleConditionType: "SET_VISIBLE_CONDITION_TYPE",
    ToggleVisibleConditionType: "TOGGLE_VISIBLE_CONDITION_TYPE",
    SetShowPointLayer: "SET_SHOW_POINT_LAYER",
    SetVisibleConditionOpacity: "SET_VISIBLE_CONDITION_OPACITY",
    SetMapStyle: "SET_MAP_STYLE",
    SetTabelogInfo: "SET_TABELOG_INFO",
    SetVisibleConditionLevels: "SET_VISIBLE_CONDITION_LEVELS",
}

export const MAP_LOCAL_STORAGE_KEY = "__map_state__"

const MapDataReducer = (state = {}, action) => {
    switch(action.type) {
        case ActionType.SetSelectedConditionType:
            state = {
                ...state,
                selectedConditionType: action.value,
                visibleConditionType: action.value,
            }
            break
        case ActionType.ToggleSelectedConditionType:
            state = {
                ...state,
                selectedConditionType: state.selectedConditionType === action.value ? null: action.value,
                visibleConditionType: state.selectedConditionType !== action.value ? action.value : state.visibleConditionType,
            }
            break
        case ActionType.SetVisibleConditionType:
            state = {...state, visibleConditionType: action.value, visibleConditionLevels: null}
            break
        case ActionType.ToggleVisibleConditionType:
            state = {...state, visibleConditionType: state.visibleConditionType === action.value ? null: action.value}
            break
        case ActionType.SetShowPointLayer:
            state = {...state, showPointLayer: action.value}
            break
        case ActionType.SetVisibleConditionOpacity:
            state = {...state, visibleConditionOpacity: action.value}
            break
        case ActionType.SetMapStyle:
            state = {...state, mapStyle: action.value}
            break
        case ActionType.SetTabelogInfo:
            return {...state, tabelogInfo: action.value}
        case ActionType.SetVisibleConditionLevels:
            return {...state, visibleConditionLevels: action.value}
        default:
            break
    }


    localStorage.setItem(MAP_LOCAL_STORAGE_KEY, JSON.stringify({
        ...state,
        tabelogInfo: null,
        visibleConditionLevels: null,
        // showConditionType: null, // 値を保存しない
        // showSelectedOnly: false,
        // mapViewBounds: null,
        // graphData: null,
        // isDragging: false,
        // dataType: DataType.TABELOG,
    }))

    return state
}

export default MapDataReducer
