import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
    IconButton,
    Typography,
} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import {MainDataContext} from "../../main";
import {Query, Rows} from "../../../manager/carto3";
import _ from "lodash";
import LOCALE_JA from "../../../resources/aggrid/locale.ja";
import UseColumnDefs from "./column";
import PropTypes from "prop-types";
import {Close as CloseIcon} from "@mui/icons-material";
import { LOCAL_STORAGE_KEY_COLUMN_STATE } from "../../../data/main/state";

const LocalStorageKeyColumnState = `${LOCAL_STORAGE_KEY_COLUMN_STATE}_tabelog`

const styles = {
    root: {
        width: '75%',
        height: '60%',
        background: 'white',
        padding: '1rem',
        boxShadow: '1px 1px 8px #000',
        borderRadius: '8px',
        display: "flex",
        flexDirection: 'column',
        position: "relative",
    }
}

const RootAssignView = (props) => {

    const { state } = useContext(MainDataContext)
    const [rowData, setRowData] = useState()
    const [salesList, setSalesList] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [editedData, setEditedData] = useState([])
    const [dataUpdating, setDataUpdating] = useState(false)
    const [openErrorDialog, setOpenErrorDialog] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [isInsertError, setIsInsertError] = useState("")
    const [assignedCount, setAssignedCount] = useState(0)
    const [gridReady, setGridReady] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [shouldAssign, setShouldAssign] = useState(true)
    const [insertedDataCount, setInsertedDataCount] = useState(0)
    const [allDataHasCurrentUser, setAllDataHasCurrentUser] = useState(true)

    const apiRef = useRef()

    const onGridReady =  useCallback((param) => {
        apiRef.current = param.api
        setGridReady(true)
    }, [])

    const onNewColumnsLoaded = _.debounce((event) => {
        if (gridReady && !isLoaded) {
            if (localStorage.getItem(LocalStorageKeyColumnState)) {
                apiRef.current.applyColumnState({state: JSON.parse(localStorage.getItem(LocalStorageKeyColumnState)), applyOrder: true})
            }
            setIsLoaded(true)
        }
    }, 500);

    const getRows = useCallback(() => {
        setOpenErrorDialog(false)
        let wheres = ["TRUE"]
        if (state.selectedList.length) {
            let ids = state.selectedList.map(v => v.TABELOG_ID)
            wheres.push(`tabelog.TABELOG_ID IN ('${ids.join("','")}')`)
        }

        Rows(`
        SELECT 
            tabelog.*,
            target.SALES_ID,
            target.SALES_NAME
        FROM ${process.env.REACT_APP_CARTO_TABLE_TABELOG_POINT} AS tabelog
        LEFT JOIN ${process.env.REACT_APP_CARTO_TABLE_NEW_SALES_TARGET_VIEW} AS target
        ON tabelog.TABELOG_ID = target.TABELOG_ID
        WHERE ${wheres.join(' AND ')}
        `).then(rows => {
            let count = 0
            const data = rows.map(row => {
                row['SALES'] = row.SALES_ID ? {...{ID: row.SALES_ID, NAME: row.SALES_NAME}} : null
                if (row.SALES_ID) {
                    count++
                }
                return row
            })
            setAssignedCount(count)
            setEditedData([])
            setRowData(data)
        })
    }, [state.selectedList])

    const getSales = useCallback(() => {
        Rows(`
        SELECT
            ID,
            NAME
        FROM ${process.env.REACT_APP_CARTO_TABLE_SALES}
        WHERE ID = ${state.user.ID}
        ORDER BY 1
        `).then(setSalesList)
    }, [state.user])

    useEffect(() => {
        getRows()
        getSales()
    }, [])

    useEffect(() => {
        if (!shouldAssign) {
            setShouldAssign(true)
            return
        }

        if (salesList.length > 0 && rowData && rowData.length > 0) {
            let sales = salesList.find(user => user.ID === state.user.ID)
            if (!sales) { return }

            apiRef.current.forEachNode(node => {
                node.setDataValue("SALES", sales)
            })
        }
    }, [salesList, rowData])


    const salesCellEditorSelector = useCallback((params) => {
        return {
            component: "agRichSelectCellEditor",
            valueFormatter: (p) => {
                return p
            },
            params: {
                values: [null, ...salesList],
                cellRenderer: (p) => {
                    if (!p.value) {
                        return <p style={{color: "grey"}}>未選択</p>
                    }
                    return p.value.NAME
                },
            },
        }

    }, [salesList])

    const columnDefs = UseColumnDefs({salesCellEditorSelector})

    const onCellValueChanged = _.debounce((event) => {
        let newEditedData = []
        let _assignedCount = 0
        let _salesDataCount = 0
        let totalCount = 0
        apiRef.current.forEachNode(node => {
            totalCount++
            if (node.data.SALES_ID || node.data.SALES) _assignedCount++ 
            if (node.data.SALES) {
                if (String(state.user.ID) === String(node.data.SALES.ID)) {
                    _salesDataCount++
                }
            }

            if (node.data.SALES || node.data.SALES_ID) {           
                if (String(node.data.SALES?.ID) !== node.data.SALES_ID) {
                    newEditedData.push({...node.data})
                }
            }
        })

        setAllDataHasCurrentUser(totalCount === _salesDataCount)
        setAssignedCount(_assignedCount)
        setEditedData(newEditedData)
    }, 100)

    const onRowSelected = (e) => {
        setSelectedRows(apiRef.current.getSelectedRows())
    }

    const selectSales = useCallback((param) => {
        let sales = salesList.find(v => v.ID === state.user.ID)
        if (!sales) { return }

        apiRef.current.forEachNodeAfterFilter(node => {
            if (param.node.data.TABELOG_ID === node.data.TABELOG_ID) {
                node.setDataValue("SALES", sales)
            }
        })

    }, [state.user, salesList])

    const bulkSelectSales = useCallback(() => {
        let sales = salesList.find(v => v.ID === state.user.ID)
        if (!sales) { return }

        const selectedTabelogIds = selectedRows.map(r => r.TABELOG_ID)
        apiRef.current.forEachNodeAfterFilter(node => {
            if (selectedTabelogIds.includes(node.data.TABELOG_ID)) {
                node.setDataValue("SALES", sales)
            }
        })

        apiRef.current.deselectAll()

    }, [state.user, salesList, selectedRows])

    const submitAssign = useCallback(() => {
        setOpenErrorDialog(false)
        let queries = []
        let values = []
        let error = false
        editedData.forEach((data) => {
             if (data.SALES_NAME !== null) {
                error = true
                return
            }

            if (data.SALES) {
                queries.push(`(:${values.length + 1}, NULL, :${values.length + 2}, :${values.length + 3}, :${values.length + 4})`)
                values.push(data.TABELOG_ID)
                values.push(data.SALES?.ID)
                values.push(data.SALES?.NAME)
                values.push(state.user.ID)
            } else {
                queries.push(`(:${values.length + 1}, NULL, NULL, NULL, :${values.length + 2})`)
                values.push(data.TABELOG_ID)
                values.push(state.user.ID)
            }
        })

        if (error) {
            setErrorMessage("担当者が登録済みのデータが含まれています")
            setIsInsertError(false)
            setOpenErrorDialog(true)
            return
        }

        if (queries.length) {
            setDataUpdating(true)

            Query(`
            INSERT INTO ${process.env.REACT_APP_CARTO_TABLE_NEW_SALES_TARGET} 
            (TABELOG_ID, HANA_NAME, SALES_ID, SALES_NAME, CREATED_SALES_ID)
            VALUES
            ${queries.join(',')}
            `, values).then(() => {
                getRows()
                setShouldAssign(false)
                setDataUpdating(false)
                setInsertedDataCount(queries.length)
                setOpenDialog(true)
            }).catch(e => {
                setErrorMessage("登録に失敗しました\n他の人が作業していないか確認して再度作業してください")
                setIsInsertError(true)
                setDataUpdating(false)
                setOpenErrorDialog(true)
            })
        }
    }, [editedData, state.user])

    const getContextMenuItems = useCallback(params => {

        if (selectedRows.length) {
            return [
                ...params.defaultItems,
                "separator",
                {
                    name: "選択を割り当てる",
                    action: () => {
                        bulkSelectSales()
                    },
                }
            ]
        }

        const appendMenus = [
            ...params.defaultItems,
            "separator",
            {
                name: "この行に割り当てる",
                action: (param) => {
//                    console.log(param)
                    selectSales(param)
                },
            }
        ]

        return appendMenus
    }, [salesList, selectedRows])

    const getDistinctFilterParams = useCallback((p) => {
        if ((rowData?.length ?? 0) === 0) {
            p.success([])
            return
        }
        let keys = {}
        rowData.forEach((row) => {
            console.log(row[p.colDef.field])
            keys[row[p.colDef.field]] = 1
        })
        p.success(Object.keys(keys).sort())
    }, [rowData])

    const getDistinctSalesFilterParams = useCallback((p) => {
        if ((rowData?.length ?? 0) === 0) {
            p.success([])
            return
        }
        let keys = {}
        rowData.forEach((row) => {
            let k = row[p.colDef.field].NAME
            keys[k] = 1
        })
        p.success(Object.keys(keys).sort())
    }, [rowData])

    return (
        <Box style={styles.root}>
            <Box style={{
                margin: "8px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}>
                <Typography variant="h6">担当者の指定</Typography>
                <Box style={{flexGrow: 1}} />
                <IconButton onClick={props.onClose} style={{marginLeft: "1rem"}}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <AgGridReact
                localeText={LOCALE_JA}
                className="ag-theme-balham"
                rowData={rowData}
                rowSelection="multiple"
                defaultColDef={{
                    filter: true,
                    floatingFilter: true,
                }}
                columnDefs={columnDefs.map(coldef => {
                    if (coldef.filterParams === "distinct") {
                        return {
                            ...coldef,
                            filterParams: {
                                values: getDistinctFilterParams
                            }
                        }
                    }
                    if (coldef.filterParams === "distinctSales") {
                        return {
                            ...coldef,
                            filterParams: {
                                values: getDistinctSalesFilterParams
                            }
                        }
                    }
                    return coldef
                })}
                onGridReady={onGridReady}
                onCellValueChanged={onCellValueChanged}
                onRowSelected={onRowSelected}
                onNewColumnsLoaded={onNewColumnsLoaded}
                suppressRowClickSelection={true}
                suppressContextMenu={true}
                getContextMenuItems={getContextMenuItems}
                sideBar={{
                    toolPanels: [
                        {
                            id: "columns",
                            labelDefault: "列選択",
                            labelKey: "columns",
                            iconKey: "columns",
                            toolPanel: "agColumnsToolPanel",
                            toolPanelParams: {
                                suppressRowGroups: true,
                                suppressValues: true,
                                suppressPivots: true,
                                suppressPivotMode: true,
                                suppressColumnFilter: true,
                                suppressColumnSelectAll: true,
                                suppressColumnExpandAll: true,
                            },
                        },
                    ],
                }}
            />
            <Box style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
                marginTop: "8px",
            }}>
                {rowData?.length && <Box style={{fontSize: "12px", color: "#3e3e3e"}}>{assignedCount.toLocaleString()}/{rowData?.length.toLocaleString()} ({((assignedCount/rowData?.length) * 100).toFixed(1)}%)</Box>}
                <Box style={{flexGrow: 1}} />
                <Button disabled={allDataHasCurrentUser} onClick={getRows} variant="contained" style={{width: "100px", backgroundColor: "#fa6565"}}>リセット</Button>
                <Button disabled={!editedData.length} onClick={submitAssign} variant="contained" style={{width: "100px", backgroundColor: "#64ca64"}}>登録</Button>
            </Box>
            {dataUpdating && (
                <Box style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#81818184",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <CircularProgress />
                </Box>
            )}

            <Dialog 
                open={openErrorDialog}
                sx={{ zIndex: 2000 }}
                >
                <DialogTitle>登録に失敗しました</DialogTitle>
                <DialogContent sx={{whiteSpace: "pre-wrap"}}>
                    {errorMessage}
                </DialogContent>
                <DialogActions>
                    {isInsertError ? (
                        <>
                            <Button onClick={getRows} variant="contained">再ロード</Button>
                            <Button onClick={submitAssign}>再試行</Button>
                            <Button onClick={() => setOpenErrorDialog(false)}>{"キャンセル"}</Button>
                        </>
                    ) : (
                        <Button onClick={() => setOpenErrorDialog(false)}>{"閉じる"}</Button>
                    )}
                </DialogActions>
            </Dialog>

            <Dialog 
                open={openDialog}
                sx={{ zIndex: 2000 }}
                fullWidth
                maxWidth="xs"
                >
                <DialogTitle>登録完了</DialogTitle>
                <DialogContent sx={{whiteSpace: "pre-wrap"}}>
                    {`${insertedDataCount}件登録しました。`}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>{"閉じる"}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )

}

RootAssignView.propTypes = {
    onClose: PropTypes.func.isRequired
}

export default RootAssignView
