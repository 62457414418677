import React, {useContext, useMemo, useState} from "react"
import {
    Box,
    Button, Divider,
    FormControlLabel, IconButton, Menu, MenuItem, Select,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import {MainDataContext} from "../../main";
import {DataType, DateMode, ViewMode} from "../../../data/main/state";
import {Person as PersonIcon, SendRounded as SendRoundedIcon} from "@mui/icons-material";
import PropTypes from "prop-types";


const styles = {
    root: {
        display: 'flex',
        height: '40px',
        flexDirection: 'row',
        padding: '0.5rem 1rem',
        justifyContent: 'center',
        background: '#eee',
    },
    spacer: {
        flexGrow: 1,
    },
    switchSelectedItemBox: {
        display: "flex",
        alignItems: "center"
    },
    switchSelectedItemText: {
        fontSize: "14px",
    },
    selectButton: {
        marginLeft: "1rem",
    },
    selectButtonText: {
        fontSize: "12px",
        marginRight: "8px",
    },
    endSpacer: {
        width: "1.8rem",
    },
}

const RootHeaderView = (props) => {

    const {
        state,
        setViewMode,
        setShowSelectedOnly,
        setUser,
        setDataType,
        setDateMode,
        setSalesInfo,
        setTabelogInfo,
        setFilterModel,
        setSessionStartTime,
    } = useContext(MainDataContext)
    const [personEl, setPersonEl] = useState()
    const modeValues = useMemo(() => state.viewMode, [state.viewMode])

    const onChangeViewMode = (event, values) => {
        setTabelogInfo(null)
        setSalesInfo(null)
        setFilterModel(null)
        if (values.length === 0) {
            return
        }
        setViewMode(values)
    }

    const onOpenPersonMenu = (e) => {
        setPersonEl(e.currentTarget)
    }

    const onClosePersonMenu = () => {
        setPersonEl(null)
    }

    const onLogout = () => {
        setUser(null)
        setSessionStartTime(null)
    }

    const onChangeDateMode = (e) => {
        setTabelogInfo(null)
        setSalesInfo(null)
        setFilterModel(null)
        setDateMode(e.target.value)
    }

    return (<Box style={styles.root}>
            <Box style={styles.spacer}></Box>
        {state.dataType === DataType.TABELOG && (<Box style={styles.switchSelectedItemBox}>
                <FormControlLabel
                    control={<Switch checked={state.showSelectedOnly} onClick={() => setShowSelectedOnly(!state.showSelectedOnly)} />}
                    label={<Typography style={styles.switchSelectedItemText}>選択アイテムのみ表示</Typography>}
                    labelPlacement="start" />
            </Box>)}
            <ToggleButtonGroup value={state.dateMode} onChange={onChangeDateMode}>
                <ToggleButton value={DateMode.Monthly}>前月</ToggleButton>
                <ToggleButton value={DateMode.Yearly}>過去12ヶ月</ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
                value={modeValues}
                size="small"
                onChange={onChangeViewMode}
            >
                <ToggleButton value={ViewMode.List} aria-label="リストの表示/非表示">リスト</ToggleButton>
                <ToggleButton value={ViewMode.Map} aria-label="地図の表示/非表示">地図</ToggleButton>
            </ToggleButtonGroup>
        {state.viewMode.includes(ViewMode.List) && (
            <Box style={{display: 'flex',
                margin: '0 1rem',
                justifyContent: 'center',
                alignItems: 'center',}}>
                <Typography>表示データ種別：</Typography>
            <Select size="small" value={state.dataType} onChange={e => setDataType(e.target.value)}>
                <MenuItem value={DataType.TABELOG}>食べログ</MenuItem>
                <MenuItem value={DataType.SALES}>売上</MenuItem>
            </Select>
            </Box>
        )}
            <Button variant="contained" style={styles.selectButton} disabled={state.selectedList === null || state.selectedList.length === 0 || state.dataType !== DataType.TABELOG} onClick={props.onAssign}>
                <Typography style={styles.selectButtonText}>担当者指定</Typography>
                <SendRoundedIcon size="small" />
            </Button>
            <Box style={styles.endSpacer}></Box>
            <IconButton onClick={onOpenPersonMenu}>
                <PersonIcon />
            </IconButton>
            <Menu
                open={personEl}
                anchorEl={personEl}
                onClose={onClosePersonMenu}
            >
                <Typography style={{textAlign: 'center',
                    color: '#848383',
                    marginBottom: '0.4rem',
                    fontSize: '12px'}}>{state.user?.NAME}</Typography>
                <Divider />
                <MenuItem onClick={onLogout}>ログアウト</MenuItem>
            </Menu>
        </Box>)

}

RootHeaderView.propTypes = {
    onAssign: PropTypes.func.isRequired,
}

export default RootHeaderView
