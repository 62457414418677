import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Map, Popup, MapProvider } from "react-map-gl";
import DeckGL from '@deck.gl/react';
import { MapView } from '@deck.gl/core';
import PropTypes from "prop-types";
import UseRootMapSalesH3Layer from "./layers/sales_h3";
import UseRootMapTabelogPointLayer from "./layers/tabelog_point";
import { MainDataContext } from "../../main";
import {Box, CircularProgress, FormControlLabel, Switch, Tooltip, Typography} from "@mui/material";
import ConditionView from "./condition";
import UseMapData from "../../../data/map";
import "./map.css";
import {WebMercatorViewport} from "deck.gl";
import _ from "lodash";
import {RootMapBasemapController as BasemapController} from "./controller/basemap";
import UseRootMapSalesPointLayer from "./layers/sales_point";
import turf from "turf"
import UseRootMapTabelogSelectLayer from "./layers/tabelog_select";
import { TabelogPointLegend } from './legend/tabelog_point';
import { DataType } from '../../../data/main/state';

const MAP_VIEW = new MapView({ repeat: true });
const INITIAL_VIEW_STATE = { longitude: 139.7708420682609, latitude: 35.68037399750397, zoom: 14 };

const styles = {
    loadingProgress: {
        position: "absolute",
        top: "1rem",
        right: "1rem",
        zIndex: 1000,
    },
    showTabelogLayerBox: {
        position: 'absolute',
        bottom: '3rem',
        right: '1rem',
        zIndex: '1000',
        background: 'white',
        padding: '8px 16px',
        borderRadius: '8px',
        boxShadow: 'rgb(0, 0, 0) 1px 1px 3px'
    }
};

const baseMapStyle = {
    version: 8,
    sources: {
        "gsi-pale": {
            type: "raster",
            tiles: ['https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png'],
            tileSize: 256,
            attribution: '国土地理院',
            minzoom: 0,
            maxzoom: 18,
        },
    },
    layers: [
        {
            id: "gsp-pale-layer",
            type: "raster",
            source: "gsi-pale",
            minzoom: 0,
            maxzoom: 24,
        }
    ]
};

export const MapDataContext = React.createContext();

const RootMap = (props) => {
    const {
        state,
        setSelectedH3List,
        appendSelectedTabelogId,
        dropSelectedTabelogId,
        setMapViewBounds,
        setTabelogInfo,
        setSalesInfo,
        setMapViewState,
    } = useContext(MainDataContext);
    const { state:mapState, setShowPointLayer } = useContext(MapDataContext)
    const [updateVersion, setUpdateVersion] = useState(0);
    const loadingRef = useRef(["tabelog", "sales"]);
    const [loading, setLoading] = useState(true);
    const [hoverInfo, setHoverInfo] = useState();
    const sectionRef = useRef()
    const [initialViewState, setInitialViewState] = useState(state.mapViewState ?? INITIAL_VIEW_STATE);
    const [isMapLoaded, setIsMapLoaded] = useState(false);

    const clickedSalesLayer = useCallback(e => {
        let selected = [...state.selectedH3List];
//        console.log(e.object.properties)
        let clicked = e.object.properties.H3S.split(",");

        let find = state.selectedH3List.find(h3 => clicked.includes(h3));
        if (find) {
            selected = selected.filter(h3 => !clicked.includes(h3));
        } else {
            clicked.forEach(h3 => !selected.includes(h3) && selected.push(h3));
        }
        setSelectedH3List(selected);
    }, [state.selectedH3List]);

    const clickedTabelogLayer = (e) => {
        setTabelogInfo(e)
    }
    const clickedSalesPointLayer = (e) => {
        setSalesInfo(e)
    }
    const hoverTabelogLayer = (e) => {
        if (e.picked) {
//            console.log(e)
            setHoverInfo({
                x: e.x,
                y: e.y,
                data: e.object.properties
            })
        } else {
//            setHoverInfo(null)
        }
    }

    const tabelogSelectLayer = UseRootMapTabelogSelectLayer({
    })

    const tabelogPointLayer = UseRootMapTabelogPointLayer({
        onLoadStart: () => {
            if (!loadingRef.current.includes("tabelog")) {
                loadingRef.current.push("tabelog");
            }
        },
        onLoadFinish: () => {
            loadingRef.current = loadingRef.current.filter(v => v !== "tabelog");
        },
        onClick: clickedTabelogLayer,
    });

    const salesPointLayer = UseRootMapSalesPointLayer({
        onLoadStart: () => {
            if (!loadingRef.current.includes("sales_point")) {
                loadingRef.current.push("sales_point")
            }
        },
        onLoadFinish: () => {
            loadingRef.current = loadingRef.current.filter(v => v !== "sales_point")
        },
        onClick: clickedSalesPointLayer,
    })

    const salesH3Layer = UseRootMapSalesH3Layer({
        onClick: clickedSalesLayer,
        onLoadStart: () => {
            if (!loadingRef.current.includes("sales")) {
                loadingRef.current.push("sales");
            }
        },
        onLoadFinish: () => {
            loadingRef.current = loadingRef.current.filter(v => v !== "sales");
        }
    });

    const layers = useMemo(() => {
        return [salesH3Layer, tabelogPointLayer, salesPointLayer, tabelogSelectLayer];
    }, [tabelogPointLayer, salesH3Layer, salesPointLayer, tabelogSelectLayer]);

    useEffect(() => {
        if (isMapLoaded) return

        if (state.mapViewState) {
            setInitialViewState({
                latitude: state.mapViewState.latitude,
                longitude: state.mapViewState.longitude,
                zoom: state.mapViewState.zoom
            })
            return
        }

        const bbox = turf.bbox(state.user.GEOMETRY)
        const viewport = new WebMercatorViewport(initialViewState)
        const { longitude, latitude, zoom} = viewport.fitBounds([[bbox[2], bbox[3]], [bbox[0],bbox[1]]], {
            width: sectionRef.current.clientWidth,
            height: sectionRef.current.clientHeight,
        })
        setInitialViewState({
            latitude: latitude,
            longitude: longitude,
            zoom: zoom
        })

    }, [sectionRef.current])

    useEffect(() => {
        let tm = setInterval(() => {
            setLoading(loadingRef.current.length > 0);
        }, 500);
        return () => {
            clearInterval(tm);
        };
    }, []);

    const onSelectChangeTabelogId = (data, selected) => {
        if (selected) {
            appendSelectedTabelogId(data.TABELOG_ID);
        } else {
            dropSelectedTabelogId(data.TABELOG_ID);
        }
    };

    const onHoverClicked = useCallback((info) => {
//        console.log("Clicked hover")
        if (state.selectedTabelogIds.includes(info.data.TABELOG_ID)) {
            dropSelectedTabelogId(info.data.TABELOG_ID)
        } else {
            appendSelectedTabelogId(info.data.TABELOG_ID)
        }
    }, [state.selectedTabelogIds])

    const updateViewBounds = useCallback((viewState) => {
        setMapViewState(viewState)
        if (state.isDragging) { return }

        const viewport = new WebMercatorViewport(viewState)
        const bounds = viewport.getBounds()
        setMapViewBounds(bounds)
    }, [state.isDragging])

    useEffect(() => {
        if (!isMapLoaded) return

        if (state.isDragging === false && state.mapViewState) {
            updateViewBounds(state.mapViewState)
        }
    }, [state.isDragging])

    const onViewStateChange = _.debounce((e) => {
        updateViewBounds(e.viewState)
    }, 500)

    const onMapLoad = (e) => {
        console.log("[MapLoaded]", e, e.target)
        e.target.setLanguage("ja")
        setIsMapLoaded(true)
    }

    const onMapClicked = (info, event) => {
        if (!info.picked) {
            setTabelogInfo(null)
            setSalesInfo(null)
        }
    }

    return (
        <section ref={sectionRef} id="view" style={{ width: props.width, height: props.height, position: 'relative' }}>
            <MapProvider>
                <DeckGL
                    className="deckgl-container"
                    layers={layers}
                    views={MAP_VIEW}
                    initialViewState={initialViewState}
                    controller={{ dragRotate: false }}
                    onViewStateChange={onViewStateChange}
                    onClick={onMapClicked}
                    style={{ position: "relative", height: '100%', width: '100%' }}
                    getCursor={({ isHovering, isDragging }) => {
                        if (isDragging) {
                            return "grabbing";
                        }
                        if (isHovering) {
                            return "pointer";
                        }
                        return "grab";
                    }}
                    key={`delgl-${updateVersion}`}
                >
                    <Map
                        onLoad={onMapLoad}
                        mapLib={import('mapbox-gl')}
                        reuseMaps
                        mapStyle={mapState.mapStyle}
                        mapboxAccessToken={process.env.REACT_APP_MAPBOX_GL_API_KEY}
                        locale="ja"
                        style={{ zIndex: 1 }}
                        className="mapbox-map-container"
                    />
                </DeckGL>
            </MapProvider>
            {loading && <CircularProgress color="warning" style={styles.loadingProgress} />}
            <ConditionView />
            <BasemapController />
            <Box style={styles.showTabelogLayerBox}>
                <FormControlLabel
                    control={<Switch size="small" checked={mapState.showPointLayer} onChange={e => setShowPointLayer(!mapState.showPointLayer)} />}
                    label="ポイント表示"
                    componentsProps={{
                        typography: {fontSize: "12px"}
                    }}
                />
            </Box>
            {state.dataType === DataType.TABELOG && <TabelogPointLegend /> }
            {hoverInfo && <Tooltip
                componentsProps={{
                    tooltip: {
                        onClick: () => onHoverClicked({...hoverInfo}),
                    },
                    popper: {
                        onClick: () => onHoverClicked({...hoverInfo}),
                    },
                    arrow: {
                        onClick: () => onHoverClicked({...hoverInfo}),
                    }
                }}
                disableFocusListener={true}
                arrow title={hoverInfo.data.HANA_NAME}>
                <div style={{position: "absolute", top: hoverInfo.y, left: hoverInfo.x, zIndex: 1000, width: "1px",height: "1px"}}></div>
            </Tooltip>}
        </section>
    );
};

RootMap.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
};

const PopupContent = React.memo(({ data, onSelectChange }) => {
    return (
        <div>{data.HANA_NAME}</div>
    );
});

PopupContent.propTypes = {
    data: PropTypes.object.isRequired,
    onSelectChange: PropTypes.func.isRequired,
};

export const RootMapView = (props) => {
    const useMapData = UseMapData();

    return (
        <MapDataContext.Provider value={useMapData}>
            <RootMap width={props.width} height={props.height} />
        </MapDataContext.Provider>
    );
};

RootMapView.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
};
