
export const ColumnDefs = [
    {
        headerName: "No",
        valueGetter: e => (e.node?.rowIndex === null || e.node?.rowIndex === undefined) ? null: e.node.rowIndex + 1,
        width: 70,
        cellStyle: {textAlign: "right"},
        filter: false,
        pinned: true,
    },
    {
        headerName: "ディーラーコード",
        field: "ZJDLRC",
        width: 120,
        filter: "agTextColumnFilter",
    },
    {
        headerName: "ディーラー名",
        field: "ZJDLRC___T",
        width: 250,
        filter: "agTextColumnFilter",
    },
    {
        headerName: "配送先店舗名",
        field: "ZJCUST___T",
        width: 250,
        filter: "agTextColumnFilter",
    },
    {
        headerName: "ディーラー住所",
        field: "ADDR_SOLD_TO",
        width: 400,
        filter: "agTextColumnFilter",
    },
    {
        headerName: "配達先",
        field: "ADDR_SHIP_TO",
        width: 400,
        filter: "agTextColumnFilter",
    },
    {
        headerName: "前月BAPC",
        field: "BAPC_LAST_MONTH",
        width: 130,
        filter: "agNumberColumnFilter",
    },
    {
        headerName: "過去12ヶ月BAPC",
        field: "BAPC_LAST_12_MONTHS",
        width: 150,
        filter: "agNumberColumnFilter",
    },
    {
        headerName: "前月NSR",
        field: "NSR_LAST_MONTH",
        width: 130,
        filter: "agNumberColumnFilter",
    },
    {
        headerName: "過去12ヶ月NSR",
        field: "NSR_LAST_12_MONTHS",
        width: 150,
        filter: "agNumberColumnFilter",
    },
    {
        headerName: "前月GP",
        field: "GP_LAST_MONTH",
        width: 130,
        filter: "agNumberColumnFilter",
    },
    {
        headerName: "過去12ヶ月GP",
        field: "GP_LAST_12_MONTHS",
        width: 150,
        filter: "agNumberColumnFilter",
    },
    {
        field: "POPULATION_LAST_MONTH",
        headerName: "前月人口",
        filter: "agNumberColumnFilter",
        width: 175,
    },
    {
        field: "POPULATION_LAST_12_MONTHS",
        headerName: "過去12ヶ月人口",
        filter: "agNumberColumnFilter",
        width: 175,
    },    
]
