import axios from "axios";
import crypto from 'crypto-js'

const TOKEN_STORAGE_KEY = "carto3_api_token"

export const Query = (sql, params = []) => {    
    //console.log("[Carto3]", "query", sql, params);
    // return axios.post(`${process.env.REACT_APP_CARTO_API_ENDPOINT}/sql/${process.env.REACT_APP_CARTO_API_CONNECTION_NAME}/query`, {
    return axios.post(`https://0nv44uuy2b.execute-api.ap-northeast-1.amazonaws.com/dev`, {
        q: crypto.AES.encrypt(sql, "cola").toString(),
        // q: sql,
        queryParameters: params,
    }, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_CARTO_ACCESS_TOKEN}`,
        }
    })
}

export const Rows = (sql, params = []) => {
    return new Promise((resolve, reject) => {
        Query(sql, params)
            .then(res => {
                resolve(res.data?.rows)
            })
            .catch(reject)
    })
}

export const First = (sql, params = []) => {
    return new Promise((resolve, reject) => {
        Rows(sql, params)
            .then(rows => [
                resolve(rows?.pop())
            ])
            .catch(reject)
    })
}

export const One = (sql, params = []) => {
    return new Promise((resolve, reject) => {
        First(sql, params)
            .then(row => {
                let key = Object.keys(row).pop()
                if (!key) { return resolve(null) }
                resolve(row[key])
            })
            .catch(reject)
    })
}

